import { Role } from './Role'

export interface RoleDTO {
  id: string
  name: string
  description: string
  hasNewPermissions: boolean
  permissions: string[]
}

export function fromModel(m: Role): RoleDTO {
  return {
    id: m.id,
    name: m.name,
    description: m.description,
    hasNewPermissions: m.hasNewPermissions,
    permissions: m.permissions,
  }
}

export function toModel(d: RoleDTO): Role {
  return new Role(d)
}
