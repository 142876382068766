import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material'
import BoxTheme from '../../components/box/BoxTheme'
import avatar from '../../assets/images/avatar-2.jpg'
import { useNavigate, useParams } from 'react-router-dom'
import DividerTheme from '../../components/divider/DividerTheme'
import { Worknote, WorknoteDTO } from '../../modules/worknotes/models/Worknote'
import { IWorknoteService } from '../../modules/worknotes'
import { WORKNOTE_SERVICE_KEY } from '../../modules/worknotes/container'
import { getIssueContainer } from '../../container/issue-module'
import CustomButton from '../../components/form/CustomButton'
import CustomTextField from '../../components/form/CustomTextField'
import { WorknoteState, worknoteStates } from 'modules/worknotes/enums/WorknoteState'
import { IWorklogService } from 'modules/issues/services/WorklogService'
import { WORKLOG_SERVICE_KEY } from 'modules/issues/container'
import { Query, QueryParam } from 'common/api/Query'
import { WorklogDTO } from 'modules/issues/models/Worklog'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Button from '@mui/material/Button'
import { Tooltip } from '@material-ui/core'
import styles from './WorknoteDetail.module.css'

export type WorknoteDetailProps = {
  worknote?: Worknote
} & RouteProps

const worknoteService = getIssueContainer().get<IWorknoteService>(WORKNOTE_SERVICE_KEY)
const worklogService = getIssueContainer().get<IWorklogService>(WORKLOG_SERVICE_KEY)

export function WorknoteDetail(props: WorknoteDetailProps) {
  const title = props.title || ''
  const { id } = useParams()
  const [worknote, setWorknote] = React.useState<WorknoteDTO>()
  const navigate = useNavigate()
  const [worklogs, setWorklogs] = useState<WorklogDTO[]>([])
  const [currentWorklogID, setCurrentWorklogID] = useState<string>()
  const [confModalOpened, setConfModalOpened] = useState<boolean>(false)
  const [isLoadingWorklogs, setIsLoadingWorklogs] = useState<boolean>(true)

  useEffect(() => {
    id &&
      worklogService
        .getFilteredList(
          new Query({
            query: [new QueryParam('worknoteID', id)],
          })
        )
        .subscribe((res) => {
          //@ts-ignore
          res && setWorklogs(res)
          setIsLoadingWorklogs(false)
        })
  }, [isLoadingWorklogs])

  React.useEffect(() => {
    if (id) {
      worknoteService.getByID(id).subscribe((w) => {
        w && setWorknote(w.toDTO())
      })
    }
  }, [])

  const formatDate = (date: Date) => {
    const d = new Date(date)
    const day = String(d.getDate()).padStart(2, '0')
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const year = d.getFullYear()
    return `${day}/${month}/${year}`
  }

  const handleFinish = () => {
    if (!worknote) return
    worknote.state = WorknoteState.Finished
    if (worknote) {
      worknoteService.update(worknote).subscribe(() => {
        navigate(-1)
      })
    }
  }

  const handleInProgress = () => {
    if (!worknote) return
    worknote.state = WorknoteState.InProgess
    if (worknote) {
      worknoteService.update(worknote).subscribe(() => {
        navigate(-1)
      })
    }
  }

  function onChangeField(field: keyof Worknote, value: any) {
    if (worknote) {
      setWorknote({ ...worknote, [field]: value })
    }
  }

  const handleDelete = (id: string) => {
    setCurrentWorklogID(id)
    setConfModalOpened(true)
  }

  const handleDeleteConfirmation = () => {
    currentWorklogID &&
      worklogService.delete(currentWorklogID).subscribe(() => {
        setIsLoadingWorklogs(true)
        setConfModalOpened(false)
      })
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  function reduceString(text: string, textLimit: number) {
    const finalChars = '...'

    return text.length > textLimit
      ? text.slice(0, textLimit - 1 - finalChars.length).concat(finalChars)
      : text
  }

  return (
    <Box>
      <DividerTheme title={title} />
      <Box style={{ marginTop: 20, display: 'inline-flex' }}>
        <Grid container spacing={2} style={{ width: '50%' }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} mb={5}>
                <BoxTheme flex={1} flexDirection={'column'} title={'Fecha de Alta'}>
                  {formatDate(worknote?.date || new Date())}
                </BoxTheme>
              </Grid>
              <Grid item xs={6} mb={5}>
                <BoxTheme title={'Responsable'}>
                  <Stack direction={'row'} spacing={2}>
                    <Avatar src={avatar} sx={{ width: 56, height: 56 }} />
                    <Typography variant={'h6'}>{'Mario Garcia Garcia'}</Typography>
                  </Stack>
                </BoxTheme>
              </Grid>
              <Grid item xs={12} mb={5}>
                <BoxTheme title={'Descripción'}>{worknote?.description}</BoxTheme>
              </Grid>
              <Grid item xs={12} mb={5}>
                <BoxTheme title={'Comentarios'}>
                  {worknote && worknote.state == WorknoteState.Finished ? (
                    worknote?.remarks
                  ) : (
                    <CustomTextField
                      onChange={(event) => onChangeField('remarks', event.target.value)}
                      fullWidth={true}
                      rows={4}
                      multiline
                      value={worknote?.remarks}
                      labelColor="black"
                    />
                  )}
                </BoxTheme>
              </Grid>
              <Grid item xs={6} mb={5}>
                <BoxTheme flex={1} flexDirection={'column'} title={'Estado'}>
                  {worknote && worknoteStates()[worknote.state]}
                </BoxTheme>
              </Grid>
              <Grid
                item
                xs={12}
                display={'flex'}
                justifyContent={'space-around'}
                style={{ marginTop: '1.5%' }}
              >
                <CustomButton
                  color={'secondary'}
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {'Cancelar'}
                </CustomButton>
                {worknote && worknote.state === WorknoteState.Pending && (
                  <CustomButton
                    color={'primary'}
                    onClick={() => {
                      handleInProgress()
                    }}
                  >
                    {'En curso'}
                  </CustomButton>
                )}
                {worknote && worknote.state === WorknoteState.InProgess && (
                  <CustomButton
                    color={'primary'}
                    onClick={() => {
                      handleFinish()
                    }}
                  >
                    {'Finalizar'}
                  </CustomButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={styles.worklogcontainer}>
          <Grid item xs={12} mb={5}>
            <BoxTheme title={'Worklogs'}>
              {worklogs.map((worklog) => (
                <Box className={styles.worklogbox}>
                  <Box className={styles.worklogleftbox}>
                    <Box className={styles.worklogboxtitles}>
                      <p className={styles.title}>Fecha</p>
                      <p className={styles.title}>Descripción</p>
                      <p className={styles.title}>Tiempo</p>
                    </Box>
                    <Box className={styles.horizontalline} />
                    <Box className={styles.worklogboxtitles}>
                      <p className={styles.datetext}>
                        {new Date(worklog.date).toLocaleDateString()}
                      </p>
                      <Box className={styles.tooltipbox}>
                        <Tooltip
                          title={<p className={styles.descriptiontext}>{worklog.description}</p>}
                        >
                          <p>{reduceString(worklog.description, 70)}</p>
                        </Tooltip>
                      </Box>
                      <p className={styles.datetext}>{worklog.time.toFixed(2)} h</p>
                    </Box>
                  </Box>
                  <Box className={styles.actionbox}>
                    <DeleteOutlineIcon
                      onClick={() => handleDelete(worklog.id)}
                      className={styles.deleteicon}
                      fontSize="large"
                    />
                  </Box>
                </Box>
              ))}
            </BoxTheme>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={confModalOpened}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setConfModalOpened(!confModalOpened)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'¿Está seguro de que desea eliminar el worklog?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Esta acción es irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfModalOpened(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#ab0f0f' }}
            onClick={handleDeleteConfirmation}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
