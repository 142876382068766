import React, { useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Box, Grid } from '@mui/material'
import DividerTheme from '../../components/divider/DividerTheme'
import CustomSelect from '../../components/form/CustomSelect'
import CustomTextField from '../../components/form/CustomTextField'
import CustomButton from '../../components/form/CustomButton'
import { emptyWorknoteDTO, toModel, WorknoteDTO } from '../../modules/worknotes/models/Worknote'
import { getUserContainer } from '../../container/user-module'
import { IWorknoteService } from '../../modules/worknotes'
import { WORKNOTE_SERVICE_KEY } from '../../modules/worknotes/container'
import { useNavigate, useParams } from 'react-router-dom'
import { getIssueContainer } from '../../container/issue-module'
import { User } from '../../modules/users/models/User'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'

const worknoteService = getIssueContainer().get<IWorknoteService>(WORKNOTE_SERVICE_KEY)
const userContainer = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

export type WorknoteEditProps = {
  id?: string
} & RouteProps

export function WorknoteEdit(props: WorknoteEditProps) {
  const title = props.title || ''
  const { id } = useParams()
  const navigate = useNavigate()
  const [users, setUsers] = React.useState<User[]>([])
  const [worknote, setWorknote] = React.useState<WorknoteDTO>(emptyWorknoteDTO())

  React.useEffect(() => {
    userContainer.getAll().subscribe((users) => users && setUsers(users))
    setWorknote({ ...worknote, issue_id: id || '' })
  }, [])

  useEffect(() => {
    if (!users) return
    users?.length > 0 && setWorknote({ ...worknote, userID: users[0].id })
  }, [users])

  function goBack() {
    navigate(-1)
  }
  function onChangeField(field: keyof WorknoteDTO, value: any) {
    if (worknote) {
      setWorknote({ ...worknote, [field]: value })
    }
  }

  function save() {
    if (worknote) {
      worknoteService.add(toModel(worknote)).subscribe(() => {
        goBack()
      })
    }
  }

  return (
    <Box>
      <DividerTheme title={title.toUpperCase()} />
      <Box style={{ marginTop: 20 }}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <CustomSelect
              onChange={(event) => onChangeField('userID', event.target.value)}
              value={worknote?.userID}
              label={'Asignar responsable'}
              id={'responsible'}
              options={users.map((u) => {
                return { value: u.id, label: u.firstName + ' ' + u.lastName }
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              onChange={(event) => onChangeField('description', event.target.value)}
              fullWidth={true}
              rows={8}
              multiline
              value={worknote?.description}
              label={'Descripción (máx. 140 caracteres)'}
              labelColor="black"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              onChange={(event) => onChangeField('remarks', event.target.value)}
              fullWidth={true}
              rows={8}
              multiline
              value={worknote?.remarks}
              label={'Comentarios'}
              labelColor="black"
            />
          </Grid>
        </Grid>
        <Box style={{ marginTop: 60 }} display={'flex'} justifyContent={'space-between'}>
          <CustomButton onClick={() => save()} color={'primary'}>
            {'GUARDAR'}
          </CustomButton>
          <CustomButton onClick={() => goBack()}>{'CANCELAR'}</CustomButton>
        </Box>
      </Box>
    </Box>
  )
}
