import React from 'react'
import { State } from '../modules/issues/models/State'

interface StateButtonProps {
  state?: State
}

function StateButton({ state }: StateButtonProps) {
  //TODO: Set color using css
  return (
    <div style={{
      borderRadius: 12,
      textAlign: 'center',
      paddingTop: 4,
      paddingBottom: 4,
      maxWidth: 250,
      border: 'solid 2px ' + state?.color,
      color: state?.color,
      width: '100%',
      fontWeight: 'bold'
    }}>
      <label>{state?.name.toUpperCase()}</label>
    </div>
  )
}

export default StateButton
