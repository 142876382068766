import { Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'

interface WidgetProps {
  title?: string,
  body?: string
  resume?: string
  color?: string
}

function Widget({ title, body, resume, color }: WidgetProps) {
  return (
    <Card sx={{ minWidth: 250 }}>
      <CardContent>
        <Typography sx={{ fontWeight: 'bold', fontSize: 17 }} color='text.secondary' gutterBottom>
          {title}
        </Typography>

        <Box component="div" style={{width: '100%'}} alignItems={'baseline'} sx={{ display: 'inline-flex' }} justifyContent={'space-between'}>
          <Box display={'block'}>
            <Typography variant='h1'>
              {body}
            </Typography>
          </Box>
          <Box display={'inline-block'}>
            <Typography variant='h2' color={color}>
              {resume}
            </Typography>
          </Box>

        </Box>

      </CardContent>
    </Card>)
}

export default Widget
