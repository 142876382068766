import { useCallback, useState } from 'react'

/*
Stores an index providing functionality to maintain it between the limits
 */
export const useIndex = (
  maxValue: number
): [index: number, increase: () => void, decrease: () => void] => {
  const [index, setIndex] = useState<number>(0)

  /*
  Increases the index value by 1 if possible
   */
  const increase = useCallback(() => {
    setIndex((prev) => {
      const newIndex: number = prev + 1
      if (newIndex <= maxValue) {
        return newIndex
      }
      return prev
    })
  }, [])

  /*
  Decreases the index value by 1 if possible
   */
  const decrease = useCallback((): void => {
    setIndex((prev) => {
      const newIndex: number = prev - 1
      if (newIndex >= 0) {
        return newIndex
      }
      return prev
    })
  }, [])

  return [index, increase, decrease]
}
