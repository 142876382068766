import { Container, IInit } from '../../../common/container/Container'
import { IssueContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { WorklogQuery, emptyWorklogDTO, toModel } from '../models/Worklog'
import { ItemList } from '../../../common/models/ItemList'
import { WorklogDTO, Worklog } from '../models/Worklog'
import { Query } from '../../../common/api/Query'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IWorklogApi extends IInit {
  getAll(): Observable<ItemList<WorklogDTO> | undefined>

  getByID(id: string): Observable<Worklog>

  add(e: WorklogDTO): Observable<Worklog | undefined>

  update(e: WorklogDTO): Observable<Worklog | undefined>

  getFilteredList(q: Query<WorklogQuery>): Observable<WorklogDTO[]>

  delete(id: string): Observable<boolean>
}

export class WorklogApi implements IWorklogApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as IssueContainerConfig).moduleFullUrl
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient
      .delete({
        url: prepareURL(`${this._url}/worklog/${id}`),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  getFilteredList(q: Query<WorklogQuery>): Observable<WorklogDTO[]> {
    return this._httpClient
      .get<ItemList<WorklogDTO>>({
        url: prepareURL(`${this._url}/worklog`, q),
      })
      .pipe(
        map((res) => res?.items),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  add(e: WorklogDTO): Observable<Worklog | undefined> {
    return this._httpClient
      .post<WorklogDTO>({
        url: `${this._url}/worklog`,
        body: e,
      })
      .pipe(
        map<WorklogDTO, Worklog>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<Worklog> {
    return this._httpClient.get<WorklogDTO>({ url: `${this._url}/worklog/${id}` }).pipe(
      map<WorklogDTO, Worklog>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(toModel(emptyWorklogDTO('')))
      })
    )
  }

  update(e: WorklogDTO): Observable<Worklog | undefined> {
    return this._httpClient.put<WorklogDTO>({ url: `${this._url}/worklog/${e.id}`, body: e }).pipe(
      map<WorklogDTO, Worklog>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAll(): Observable<ItemList<WorklogDTO> | undefined> {
    return this._httpClient
      .get<ItemList<WorklogDTO>>({
        url: `${this._url}/worklog`,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
