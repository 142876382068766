import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import CustomButton from '../../components/form/CustomButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ISSUES } from '../../routes/routes-constants'
import { Issue } from '../../modules/issues/models/Issue'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import BoxTheme from '../../components/box/BoxTheme'
import StateButton from '../../components/StateButton'
import { getIssueContainer } from '../../container/issue-module'
import avatar from '../../assets/images/avatar-2.jpg'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files/container'
import { FileDTO, fromModel } from '../../modules/files/models/File'
import { Query, QueryParam } from 'common/api/Query'
import { ImageType } from '../../modules/files/enums/ImageType'

export type ViewIssueDialogProps = {
  open: boolean
  issueId: string
  onClose?: () => void
  setSelected?: (st: string) => void
}

interface ImagesView {
  [ImageType.ISSUE_IMAGE]?: FileDTO
  [ImageType.EXECUTION_IMAGE]?: FileDTO
  [ImageType.RESOLUTION_IMAGE]?: FileDTO
}

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)

export function ViewIssueDialog(props: ViewIssueDialogProps) {
  const navigate = useNavigate()
  const [issue, setIssue] = useState<Issue>()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [images, setImages] = useState<ImagesView>({})

  useEffect(() => {
    fileService
      .getFilteredList(
        new Query({
          query: [new QueryParam('issueID', props.issueId), new QueryParam('index', 0)],
        })
      )
      .subscribe((res) => {
        const importedImages: ImagesView = {}
        res.items.forEach((item) => {
          const image = {
            ...fromModel(item),
            base64: `data:image/${item.extension};base64,` + item.base64,
          }
          importedImages[image.type] = image
        })
        setImages(importedImages)
      })
  }, [])

  useEffect(() => {
    setOpen(props.open)
    if (props.issueId) {
      issueService.getByID(props.issueId).subscribe((i) => {
        i && setIssue(i)
      })
    }
  }, [props])

  useEffect(() => {
    if (issue) {
      setLoading(false)
    }
  }, [issue])

  function handleClose() {
    setOpen(false)
    props.onClose && props.onClose()
  }

  const renderImageOrDefaultText = (type: ImageType) => {
    return images[type]?.base64 ? (
      <Box style={{ width: '100%' }} component="img" src={images[type]?.base64}></Box>
    ) : (
      <Box style={{ width: '100%' }}>{'Sin Imagen'}</Box>
    )
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'md'} onClose={handleClose}>
      <DialogTitle>Ver Incidencia</DialogTitle>
      <DialogContent>
        <Box style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Expediente'}>{issue?.incNumber}</BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}></Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Tipo'}>{issue?.type.name}</BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Estado'}>
                    <StateButton state={issue?.state} />
                  </BoxTheme>
                </Grid>
                <Grid item xs={12} mb={5}>
                  <BoxTheme title={'Descripción'}>{issue?.description}</BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Informador'}>
                    <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
                      <Avatar src={avatar} sx={{ width: 56, height: 56 }} />
                      <Typography variant={'h6'}>
                        {issue?.user.firstName
                          ? `${issue.user.firstName} ${issue.user.lastName}`
                          : issue?.email}
                      </Typography>
                    </Stack>
                  </BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Responsable'}>
                    <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
                      {issue?.responsible.firstName ? (
                        <>
                          <Avatar src={avatar} sx={{ width: 56, height: 56 }} />
                          <Typography variant={'h6'}>
                            {issue.responsible.firstName + ' ' + issue.responsible.lastName}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Avatar
                            src={avatar}
                            sx={{ width: 56, height: 56 }}
                            style={{ filter: 'grayscale(1)' }}
                          />
                          <Typography variant={'h6'}>{'No asignado'}</Typography>
                        </>
                      )}
                    </Stack>
                  </BoxTheme>
                </Grid>
                <Grid item xs={4} mb={5}>
                  <BoxTheme title={'Imagen Incidencia'}>
                    {renderImageOrDefaultText(ImageType.ISSUE_IMAGE)}
                  </BoxTheme>
                </Grid>
                <Grid item xs={4} mb={5}>
                  <BoxTheme title={'Imagen Ejecución'}>
                    {renderImageOrDefaultText(ImageType.EXECUTION_IMAGE)}
                  </BoxTheme>
                </Grid>
                <Grid item xs={4} mb={5}>
                  <BoxTheme title={'Imagen Resolución'}>
                    {renderImageOrDefaultText(ImageType.RESOLUTION_IMAGE)}
                  </BoxTheme>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton
          color={'primary'}
          onClick={() => {
            props.setSelected && props.setSelected(ROUTE_ISSUES)
            navigate(`${ROUTE_ISSUES}/${issue?.id}`)
          }}
        >
          {'VER'}
        </CustomButton>
        <CustomButton
          color={'primary'}
          onClick={() => {
            props.setSelected && props.setSelected(ROUTE_ISSUES)
            navigate(`${ROUTE_ISSUES}/edit/${issue?.id}`, { state: { id: issue?.id } })
          }}
        >
          {'EDITAR'}
        </CustomButton>
        <CustomButton onClick={() => handleClose()}>{'CERRAR'}</CustomButton>
      </DialogActions>
    </Dialog>
  )
}
