import { Container } from '../../common/container/Container'

export type IssueContainerConfig = {
  moduleFullUrl: string
}

export type IssueProps = {
  parentContainer: Container
  config: IssueContainerConfig
}

export const ISSUES_MODULE = Symbol('ISSUES_MODULE')

export const ISSUE_API_KEY = Symbol('ISSUE_API_KEY')

export const STATE_API_KEY = Symbol('STATE_API_KEY')

export const ISSUETYPE_API_KEY = Symbol('ISSUETYPE_API_KEY')

export const ISSUEHISTORY_API_KEY = Symbol('ISSUEHISTORY_API_KEY')

export const ADDRESS_API_KEY = Symbol('ADDRESS_API_KEY')

export const ISSUE_SERVICE_KEY = Symbol('ISSUE_SERVICE_KEY')

export const ISSUEHISTORY_SERVICE_KEY = Symbol('ISSUEHISTORY_SERVICE_KEY')

export const STATE_SERVICE_KEY = Symbol('STATE_SERVICE_KEY')

export const ISSUETYPE_SERVICE_KEY = Symbol('ISSUETYPE_SERVICE_KEY')

export const ADDRESS_SERVICE_KEY = Symbol('ADDRESS_SERVICE_KEY')

export const WORKLOG_API_KEY = Symbol('WORKLOG_API_KEY')

export const WORKLOG_SERVICE_KEY = Symbol('WORKLOG_SERVICE_KEY')
