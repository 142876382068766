import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import iconMap from '../../assets/left_menu/mapa.svg'
import iconIssues from '../../assets/left_menu/incidencias.svg'
import iconAdmin from '../../assets/left_menu/administracion.svg'
import iconDashboard from '../../assets/left_menu/dashboard.svg'
import styles from '../header/Header.module.css'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { Link } from 'react-router-dom'
import { ROUTE_HOME, ROUTE_ISSUES, ROUTE_MAP, ROUTE_USERS } from 'routes/routes-constants'

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type ListItemsProps = {
  selected: string
  handleChangeSelected: (st: string) => void
}

const ListItems = (props: ListItemsProps) => {
  const { t } = useTranslation()

  const isIconActive = (path: string) => {
    if (path === props.selected) {
      return styles.iconContainerActive
    }
  }

  const isIconActiveText = (path: string) => {
    if (path === props.selected) {
      return styles.textMenuActive
    }
  }

  const changeStyleIcon = (path: string) => {
    if (path === props.selected) {
      return styles.iconActive
    }
  }

  const loggedUser = loggedUserService.get()

  return (
    <List style={{ marginTop: 55 }}>
      <Link to={ROUTE_HOME} style={{ textDecorationLine: 'none' }}>
        {
          <ListItem
            className={`${styles.iconContainer} ${isIconActive(ROUTE_HOME)}`}
            style={{ paddingTop: 20, marginTop: '13%' }}
            onClick={() => props.handleChangeSelected(ROUTE_HOME)}
          >
            <ListItemIcon>
              <img className={changeStyleIcon(ROUTE_HOME)} src={iconDashboard} />
            </ListItemIcon>
            <ListItemText
              className={`${styles.textMenu} ${isIconActiveText(ROUTE_HOME)}`}
              primary={t('dashboard')}
            />
          </ListItem>
        }
      </Link>

      <Link to={ROUTE_ISSUES} style={{ textDecorationLine: 'none' }}>
        {
          <div style={{ marginTop: '14%' }}>
            <ListItem
              className={`${styles.iconContainer} ${isIconActive(ROUTE_ISSUES)}`}
              onClick={() => props.handleChangeSelected(ROUTE_ISSUES)}
            >
              <ListItemIcon>
                <img className={changeStyleIcon(ROUTE_ISSUES)} src={iconIssues} />
              </ListItemIcon>
              <ListItemText
                className={`${styles.textMenu} ${isIconActiveText(ROUTE_ISSUES)}`}
                primary={t('issues')}
              />
            </ListItem>
          </div>
        }
      </Link>

      <Link to={ROUTE_MAP} style={{ textDecorationLine: 'none' }}>
        {
          <div style={{ marginTop: '14%' }}>
            <ListItem
              className={`${styles.iconContainer} ${isIconActive(ROUTE_MAP)}`}
              onClick={() => props.handleChangeSelected(ROUTE_MAP)}
            >
              <ListItemIcon>
                <img className={changeStyleIcon(ROUTE_MAP)} src={iconMap} />
              </ListItemIcon>
              <ListItemText
                className={`${styles.textMenu} ${isIconActiveText(ROUTE_MAP)}`}
                primary={t('map')}
              />
            </ListItem>
          </div>
        }
      </Link>

      <Link to={ROUTE_USERS} style={{ textDecorationLine: 'none' }}>
        {
          <>
            {loggedUser?.roles.includes('admin') && (
              <div style={{ marginTop: '14%' }}>
                <ListItem
                  className={`${styles.iconContainer} ${isIconActive(ROUTE_USERS)}`}
                  onClick={() => props.handleChangeSelected(ROUTE_USERS)}
                >
                  <ListItemIcon>
                    <img className={changeStyleIcon(ROUTE_USERS)} src={iconAdmin} />
                  </ListItemIcon>
                  <ListItemText
                    className={`${styles.textMenu} ${isIconActiveText(ROUTE_USERS)}`}
                    primary={t('users')}
                  />
                </ListItem>
              </div>
            )}
          </>
        }
      </Link>
    </List>
  )
}

export default ListItems
