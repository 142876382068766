import React, { useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Grid } from '@mui/material'
import { getIssueContainer } from '../../container/issue-module'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import Widget from '../../components/dashboard/Widget'
import { IIssueService } from '../../modules/issues'

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)

export function Home(props: RouteProps) {
  const [states, setStates] = React.useState<any[]>([])

  useEffect(() => {
    issueService.getDashBoardData().subscribe((res) => res && setStates(res))
  }, [])

  /**
   * 0 => Incidencia Solicitada
   * 1 => En Gestión
   * 2 => En Ejecución
   * 3 => Gestionada / Solucionada
   * 4 => No pertenece al servicio
   */
  function widget(st: any, index: number) {
    return (
      <Widget key={index} color={st.Color} title={st.Type} resume={st.Percentage} body={st.Total} />
    )
  }

  return (
    <Grid style={{ padding: 20 }} container rowSpacing={3} columnSpacing={3}>
      {states.map((st, i) => (
        <Grid key={i} item xs={4}>
          {widget(st, i)}
        </Grid>
      ))}
    </Grid>
  )
}
