import React, { useState, useEffect } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import { getUserContainer } from '../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../modules/users'
import { Home } from '../pages/home'
import { Map } from '../pages/map'
import {
  ROUTE_HOME,
  ROUTE_ISSUES,
  ROUTE_ISSUES_EDIT,
  ROUTE_ISSUES_NEW,
  ROUTE_ISSUES_VIEW,
  ROUTE_MAP,
  ROUTE_USERS,
  ROUTE_USERS_EDIT,
  ROUTE_USERS_NEW,
  ROUTE_WORKNOTES_EDIT,
  ROUTE_WORKNOTES_NEW,
  ROUTE_LOGIN,
  ROUTE_WORKNOTES_VIEW,
  ROUTE_PASSWORD_RECOVER,
  ROUTE_DEFAULT,
} from './routes-constants'
import { Issues } from '../pages/issues'
import { useTranslation } from 'react-i18next'
import { IssueDetail } from '../pages/issues/IssueDetail'
import { IssueEdit } from '../pages/issues/IssueEdit'
import { WorknoteDetail } from '../pages/worknotes/WorknoteDetail'
import { WorknoteEdit } from '../pages/worknotes/WorknoteEdit'
import { Users } from '../pages/users/Users'
import { UserEdit } from '../pages/users/UserEdit'
import Login from 'Login'
import RecoveryPassword from 'RecoveryPassword'
import { LoggedUser } from 'modules/users/models/LoggedUser'
import { Outlet } from 'react-router-dom'
import CustomAppBar from '../components/header/CustomAppBar'
import CustomDrawer from '../components/header-component/CustomDrawer'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { esES, enUS, itIT } from '@mui/material/locale'
import { Language } from '../i18n'

const TRANSLATIONS = {
  [Language.SPANISH]: esES,
  [Language.ENGLISH]: enUS,
  [Language.ITALIAN]: itIT,
}

export type RouteProps = {
  title?: string
  subtitle?: string
  id?: string
}

interface AdminLayoutProps {
  language: Language
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const AppRouter: React.FC = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [user, setUser] = useState<LoggedUser | undefined>(loggedUser)
  const [selected, setSelected] = useState<string>(ROUTE_HOME)
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname

    switch (true) {
      case path == ROUTE_HOME:
        setSelected(ROUTE_HOME)
        break
      case path.includes(ROUTE_ISSUES):
        setSelected(ROUTE_ISSUES)
        break
      case path.includes(ROUTE_MAP):
        setSelected(ROUTE_MAP)
        break
      case path.includes(ROUTE_USERS):
        setSelected(ROUTE_USERS)
        break
    }
  }, [])

  const LoginLayout = () => (
    <div>
      <Outlet />
    </div>
  )

  const handleChangeSelected = (st: string) => {
    setSelected(st)
  }

  const AdminLayout = ({ language }: AdminLayoutProps) => {
    const theme = createTheme(TRANSLATIONS[language])
    return (
      <ThemeProvider theme={theme}>
        <div className={`mainContainer ${selected !== ROUTE_MAP && 'mainContainerPadding'}`}>
          <CustomAppBar />
          <CustomDrawer selected={selected} handleChangeSelected={handleChangeSelected} />
          <Outlet />
        </div>
      </ThemeProvider>
    )
  }

  return (
    <>
      <Routes>
        {user ? (
          <>
            <Route element={<AdminLayout language={user.language} />}>
              <Route path={ROUTE_HOME} element={<Home title={t('home')} />} />
              <Route
                path={ROUTE_MAP}
                element={<Map title={t('map')} setSelected={handleChangeSelected} />}
              />
              <Route path={ROUTE_ISSUES} element={<Issues title={t('issues')} />} />
              <Route path={ROUTE_ISSUES_VIEW} element={<IssueDetail title={t('issue_detail')} />} />
              <Route path={ROUTE_ISSUES_EDIT} element={<IssueEdit title={t('issue_edit')} />} />
              <Route path={ROUTE_ISSUES_NEW} element={<IssueEdit title={t('issue_new')} />} />
              <Route
                path={ROUTE_WORKNOTES_VIEW}
                element={<WorknoteDetail title={t('worknote_detail')} />}
              />
              <Route
                path={ROUTE_WORKNOTES_EDIT}
                element={<WorknoteEdit title={t('worknote_detail')} />}
              />
              <Route
                path={ROUTE_WORKNOTES_NEW}
                element={<WorknoteEdit title={t('worknote_new')} />}
              />
              <Route
                path={ROUTE_WORKNOTES_NEW}
                element={<WorknoteEdit title={t('worknote_new')} />}
              />
              {loggedUser?.roles.includes('admin') && (
                <>
                  <Route path={ROUTE_USERS} element={<Users title={t('users')} />} />
                  <Route path={ROUTE_USERS_EDIT} element={<UserEdit title={t('users_edit')} />} />
                  <Route path={ROUTE_USERS_NEW} element={<UserEdit title={t('users_new')} />} />
                </>
              )}
              <Route path={ROUTE_DEFAULT} element={<Navigate replace to={ROUTE_HOME} />} />
            </Route>
          </>
        ) : (
          <>
            <Route element={<LoginLayout />}>
              <Route path={ROUTE_LOGIN} element={<Login onLogin={(u) => setUser(u)} />} />
              <Route path={ROUTE_PASSWORD_RECOVER} element={<RecoveryPassword />} />
              <Route path={ROUTE_DEFAULT} element={<Navigate replace to={ROUTE_LOGIN} />} />
            </Route>
          </>
        )}
      </Routes>
    </>
  )
}
