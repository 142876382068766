import { Query } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'

export type RoleProps = {
  id?: string
  name: string
  description: string
  hasNewPermissions: boolean
  permissions: string[]
}

export class Role {
  private readonly _id: string
  private readonly _name: string
  private readonly _description: string
  private _hasNewPermissions: boolean
  private readonly _permissions: string[]

  constructor(p: RoleProps) {
    this._id = p.id || uuidv4()
    this._name = p.name
    this._description = p.description
    this._hasNewPermissions = p.hasNewPermissions
    this._permissions = p.permissions
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get description(): string {
    return this._description
  }

  get hasNewPermissions(): boolean {
    return this._hasNewPermissions
  }

  set hasNewPermissions(value: boolean) {
    this._hasNewPermissions = value
  }

  get permissions(): string[] {
    return this._permissions
  }
}

export class RoleQuery extends Query<Role> {}
