import {
  RecoverPasswordDTO,
  toModel as userToModel,
  User,
  UserDTO,
  UserQuery,
} from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { UserContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { AuthDTO } from '../models/AuthDTO'
import { LoggedUserDTO } from '../models/LoggedUserDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UpdatePassword } from '../models/UpdatePassword'
import { UpdatePasswordDTO } from '../models/UpdatePasswordDTO'
import { RegisterUserDTO } from '../models/RegisterUserDTO'
import { UserPending } from '../models/UserPending'
import { LoggedUser } from '../models/LoggedUser'

export interface IUserApi extends IInit {
  getByID(id: string): Observable<User | undefined>

  passwordRecover(email: string): Observable<boolean>

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined>

  getByUsername(username: string): Observable<User | undefined>

  getFilteredItems(q: Query<UserQuery>): Observable<User[]>

  getUserCount(q: Query<UserQuery>): Observable<number>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: UserDTO): Observable<User | undefined>

  update(e: UserDTO): Observable<User | undefined>

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined>

  logout(): Observable<boolean>

  getOrgLocation(): Observable<any | undefined>
}

export class UserApi implements IUserApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  add(e: UserDTO): Observable<User | undefined> {
    return this._httpClient
      .post<User>({
        url: `${this._url}/users`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  register(e: RegisterUserDTO): Observable<RegisterUserDTO | undefined> {
    return this._httpClient
      .post<RegisterUserDTO>({
        url: `${this._url}/register`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/users/${id}` }).pipe(
      map<UserDTO, User>((dto) => userToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getOrgLocation(): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/users/users/localization` }).pipe(
      map<any, any>((dto) => {
        return dto
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByUsername(username: string): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/users/byName/${username}` }).pipe(
      map<UserDTO, User>((dto) => userToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredItems(q: Query<UserQuery>): Observable<User[]> {
    return this._httpClient.get<ItemList<User>>({ url: prepareURL(`${this._url}/users`, q) }).pipe(
      map((res) => res?.items),
      map<UserDTO[], User[]>((dto) => dto.map((d) => userToModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getUserCount(q: Query<UserQuery>): Observable<number> {
    return this._httpClient.get<number>({ url: prepareURL(`${this._url}/user-count`, q) }).pipe(
      map((res) => res),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(-1)
      })
    )
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({ url: prepareURL(`${this._url}/users-pages`, q) })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => userToModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        })
      )
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._httpClient.put<User>({ url: `${this._url}/users`, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined> {
    return this._httpClient.post<LoggedUserDTO>({ url: this._url + '/login', body: a }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  logout(): Observable<boolean> {
    return this._httpClient.post<boolean>({ url: this._url + '/logout' }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  invite(email: string, circleID: string, roleName: string): Observable<boolean> {
    return this._httpClient
      .post<true>({ url: `${this._url}/users-invite/${email}/${circleID}/${roleName}` })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  passwordRecover(email: string): Observable<boolean> {
    return this._httpClient
      .post<true>({ url: `${this._url}/recover-password`, body: { email: email } })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined> {
    return this._httpClient
      .post<UpdatePassword>({ url: `${this._url}/change-password-email`, body: e })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  checkToken(token: string): Observable<UserPending | undefined> {
    return this._httpClient
      .post<UserPending>({ url: `${this._url}/token`, body: { token: token } })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
