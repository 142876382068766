import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { TablePagination } from '@mui/material'
import iconMore from '../../assets/table/icon-more.svg'
import { useState } from 'react'

type DataTableProps = {
  columns: any[]
  data?: any[]
  currentPage?: number
  rowsPerPage?: number
  rowsPerPageOptions: number[]
  totalRows: number
  onRowClick?: (row: any) => void
  withActions?: boolean
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  handleRowsPerPageChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    padding: 8,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:child td, &child th': {
    border: 0,
  },
}))

export default function DataTable({
  columns,
  currentPage,
  rowsPerPage,
  rowsPerPageOptions,
  totalRows,
  onRowClick,
  data,
  withActions = true,
  handleChangePage,
  handleRowsPerPageChange,
}: DataTableProps) {
  const handleMoreClick = (row: any) => {
    onRowClick?.(row)
  }

  return (
    <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((c) => (
              <StyledTableCell align={'left'} style={{ width: c.size }}>
                {c.name}
              </StyledTableCell>
            ))}
            {withActions && <StyledTableCell align={'left'}></StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <StyledTableRow key={row.id}>
              {columns?.map((column) => (
                <StyledTableCell key={'c_' + column.id} align="left" style={{ width: column.size }}>
                  {row[column.id]}
                </StyledTableCell>
              ))}
              {withActions && (
                <StyledTableCell
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleMoreClick(row)}
                  align={'center'}
                >
                  <img src={iconMore} />
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
        <TablePagination
          count={totalRows}
          rowsPerPage={rowsPerPage || totalRows}
          page={currentPage || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </Table>
    </TableContainer>
  )
}
