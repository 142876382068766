import { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { Issue } from '../../modules/issues/models/Issue'
import BoxTheme from '../../components/box/BoxTheme'
import avatar from '../../assets/images/avatar-2.jpg'
import IssuesMap from '../../components/map/IssuesMap'
import { Coords } from 'google-map-react'
import { useParams } from 'react-router-dom'
import DividerTheme from '../../components/divider/DividerTheme'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import CustomButton from '../../components/form/CustomButton'
import PDFViewer from '../../components/PDFViewer'
import StateButton from '../../components/StateButton'
import { getIssueContainer } from '../../container/issue-module'
import { FileCount, FileDTO, fromModel } from '../../modules/files/models/File'
import { FILE_SERVICE_KEY } from 'modules/files/container'
import { FileService } from 'modules/files/services/FileService'
import { Query, QueryParam } from 'common/api/Query'
import { ActionsTable } from './ActionsTable'
import { WorknotesTable } from './WorknotesTable'
import { ImageType } from '../../modules/files/enums/ImageType'
import PhotoViewer from '../../components/photo-viewer/PhotoViewer'

export type IssueDetailProps = {
  issue?: Issue
} & RouteProps

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)

interface ImagesView {
  [ImageType.ISSUE_IMAGE]?: FileDTO
  [ImageType.EXECUTION_IMAGE]?: FileDTO
  [ImageType.RESOLUTION_IMAGE]?: FileDTO
}

interface PhotoViewerValues {
  title: string
  totalPhotos: number
  type: ImageType
}

const IMAGES_TITLES = {
  [ImageType.ISSUE_IMAGE]: 'Imágenes de la Incidencia',
  [ImageType.EXECUTION_IMAGE]: 'Imágenes de la Ejecución',
  [ImageType.RESOLUTION_IMAGE]: 'Imágenes de la Resolución',
}

export function IssueDetail(props: IssueDetailProps) {
  const title = props.title || ''
  const { id } = useParams()
  const [showReport, setShowReport] = useState<boolean>(false)
  const [viewPhoto, setViewPhoto] = useState<boolean>(false)
  const [issue, setIssue] = useState<Issue>()
  const [images, setImages] = useState<ImagesView>({})
  const [photoViewerValues, setPhotoViewerValues] = useState<PhotoViewerValues>({
    title: '',
    type: ImageType.ISSUE_IMAGE,
    totalPhotos: 0,
  })
  const [imagesCount, setImagesCount] = useState<FileCount>({
    [ImageType.ISSUE_IMAGE]: 0,
    [ImageType.EXECUTION_IMAGE]: 0,
    [ImageType.RESOLUTION_IMAGE]: 0,
  })
  const [report, setReport] = useState<string>()

  useEffect(() => {
    fileService
      .getFilteredList(
        new Query({
          query: [new QueryParam('issueID', id || ''), new QueryParam('index', 0)],
        })
      )
      .subscribe((res) => {
        const importedImages: ImagesView = {}
        res.items.forEach((item) => {
          const image = {
            ...fromModel(item),
            base64: `data:image/${item.extension};base64,` + item.base64,
          }
          importedImages[image.type] = image
        })
        setImages(importedImages)
      })
  }, [])

  useEffect(() => {
    if (id) {
      issueService.getByID(id).subscribe((i) => i && setIssue(i))
    }
  }, [id])

  useEffect(() => {
    fileService
      .getCountByType(
        new Query({
          query: [new QueryParam('issueID', id || '')],
        })
      )
      .subscribe((res) => setImagesCount(res))
  }, [])

  function downloadPDF() {
    const element = document.createElement('a')
    element.href = report || ''
    element.download = 'report_' + issue?.incNumber + '.pdf'

    document.body.appendChild(element)
    element.click()
  }

  const handleShowReport = () => {
    if (id) {
      issueService.generateReport(id).subscribe(async (res) => {
        const base64File = `data:${res.type}/${res.extension};base64,${res.base64}`
        const blob = await fetch(base64File).then((data) => data.blob())
        setReport(URL.createObjectURL(blob))
        setShowReport(true)
      })
    }
  }

  const renderImageOrDefaultText = (type: ImageType) => {
    const handleShowImages = () => {
      setPhotoViewerValues({
        title: IMAGES_TITLES[type],
        type: type,
        totalPhotos: imagesCount[type],
      })
      setViewPhoto(true)
    }

    return (
      <Box style={{ width: '100%' }}>
        {imagesCount[type] === 0 ? (
          <Box style={{ marginBottom: 20 }}>{'Sin imágenes'}</Box>
        ) : (
          <>
            <Box style={{ marginBottom: 20 }}>
              {imagesCount[type] > 1 ? `${imagesCount[type]} imágenes` : `1 imagen`}
            </Box>
            <Box
              onClick={handleShowImages}
              style={{
                aspectRatio: '1 / 1',
                borderRadius: '20px',
                overflow: 'hidden',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${images[type]?.base64})`,
                cursor: 'pointer',
              }}
            ></Box>
          </>
        )}
      </Box>
    )
  }

  const center: Coords = (issue && { lat: issue.lat, lng: issue.lng }) || {
    lat: 37.60512,
    lng: -0.98623,
  }

  const zoom: number = 16

  return (
    <Box>
      <DividerTheme title={title} />
      {showReport && (
        <Dialog
          open={showReport}
          fullWidth={true}
          maxWidth={'md'}
          onClose={() => setShowReport(false)}
        >
          <DialogTitle id="scroll-dialog-title">Informe Incidencia</DialogTitle>
          <DialogActions>
            <CustomButton color={'primary'} onClick={() => downloadPDF()} fullWidth={true}>
              {'DESCARGAR'}
            </CustomButton>
          </DialogActions>
          <DialogContent>
            <PDFViewer file={report || ''} />
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={() => setShowReport(false)} fullWidth={true}>
              {'CERRAR'}
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
      {viewPhoto && (
        <PhotoViewer
          visible={viewPhoto}
          hide={() => setViewPhoto(false)}
          title={photoViewerValues.title}
          issueID={id || ''}
          type={photoViewerValues.type}
          totalPhotos={photoViewerValues.totalPhotos}
        />
      )}
      <Box style={{ marginTop: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} mb={5}>
                <BoxTheme title={'Expediente'}>{issue?.incNumber}</BoxTheme>
              </Grid>
              <Grid item xs={6} mb={5}></Grid>
              <Grid item xs={6} mb={5}>
                <BoxTheme title={'Tipo'}>{issue?.type.name}</BoxTheme>
              </Grid>
              <Grid item xs={6} mb={5}>
                <BoxTheme title={'Estado'}>
                  <StateButton state={issue?.state} />
                </BoxTheme>
              </Grid>
              <Grid item xs={12} mb={5}>
                <BoxTheme title={'Descripción'}>{issue?.description}</BoxTheme>
              </Grid>
              <Grid item xs={6} mb={5}>
                <BoxTheme title={'Informador'}>
                  <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
                    <Avatar src={avatar} sx={{ width: 56, height: 56 }} />
                    <Typography variant={'h6'}>
                      {issue?.user.firstName
                        ? `${issue.user.firstName} ${issue.user.lastName}`
                        : issue?.email}
                    </Typography>
                  </Stack>
                </BoxTheme>
              </Grid>
              <Grid item xs={6} mb={5}>
                <BoxTheme title={'Responsable'}>
                  <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
                    {issue?.responsible.firstName ? (
                      <>
                        <Avatar src={avatar} sx={{ width: 56, height: 56 }} />
                        <Typography variant={'h6'}>
                          {issue.responsible.firstName + ' ' + issue.responsible.lastName}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Avatar
                          src={avatar}
                          sx={{ width: 56, height: 56 }}
                          style={{ filter: 'grayscale(1)' }}
                        />
                        <Typography variant={'h6'}>{'No asignado'}</Typography>
                      </>
                    )}
                  </Stack>
                </BoxTheme>
              </Grid>
              <Grid item xs={4} mb={5}>
                <BoxTheme title={'Imágenes Incidencia'}>
                  {renderImageOrDefaultText(ImageType.ISSUE_IMAGE)}
                </BoxTheme>
              </Grid>
              <Grid item xs={4} mb={5}>
                <BoxTheme title={'Imágenes Ejecución'}>
                  {renderImageOrDefaultText(ImageType.EXECUTION_IMAGE)}
                </BoxTheme>
              </Grid>
              <Grid item xs={4} mb={5}>
                <BoxTheme title={'Imágenes Resolución'}>
                  {renderImageOrDefaultText(ImageType.RESOLUTION_IMAGE)}
                </BoxTheme>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ marginTop: 15 }} display={'flex'} justifyContent={'space-between'}>
                  <CustomButton onClick={handleShowReport} fullWidth={true} color={'primary'}>
                    {'INFORME'}
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} mb={5}>
            <IssuesMap issues={issue ? [issue] : []} center={center} zoom={zoom} />
          </Grid>
          <Grid style={{ marginTop: 20 }} item container xs={12} spacing={2}>
            <ActionsTable issueID={id || ''} />
            <WorknotesTable issueID={id || ''} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
