import { ImageType } from '../../modules/files/enums/ImageType'
import { useIndex } from './useIndex'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { getIssueContainer } from '../../container/issue-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { Query, QueryParam } from '../../common/api/Query'
import { FileDTO, fromModel } from '../../modules/files/models/File'
import CustomButton from '../form/CustomButton'
import DividerTheme from '../divider/DividerTheme'

interface PhotoViewerProps {
  visible: boolean
  hide: () => void
  title: string
  issueID: string
  type: ImageType
  totalPhotos: number
}

const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)

const PhotoViewer = ({
  visible,
  hide,
  title,
  issueID,
  type,
  totalPhotos,
}: PhotoViewerProps): JSX.Element => {
  const [photoIndex, nextPhoto, previousPhoto] = useIndex(totalPhotos - 1)
  const [image, setImage] = useState<string>('')

  useEffect(() => {
    fileService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam('issueID', issueID),
            new QueryParam('type', type),
            new QueryParam('index', photoIndex),
          ],
        })
      )
      .subscribe((res) => {
        const image: FileDTO = { ...fromModel(res.items[0]) }
        setImage(`data:image/${image.extension};base64,${image.base64}`)
      })
  }, [photoIndex])

  const handleKey = useCallback((event: WindowEventMap['keydown']) => {
    if (event.key === 'ArrowRight') {
      nextPhoto()
    } else if (event.key === 'ArrowLeft') {
      previousPhoto()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [])

  return (
    <Dialog open={visible} fullWidth={true} maxWidth={'md'} onClose={hide}>
      <DialogTitle>
        <DividerTheme fontSize={25} title={title} />
      </DialogTitle>
      <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={image} style={{ maxWidth: '30vw', maxHeight: '60vh', borderRadius: '5px' }} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <CustomButton onClick={previousPhoto} color={photoIndex > 0 ? 'primary' : 'secondary'}>
          {'ANTERIOR'}
        </CustomButton>
        <CustomButton onClick={hide} color={'secondary'}>
          {'CERRAR'}
        </CustomButton>
        <CustomButton
          onClick={nextPhoto}
          color={photoIndex < totalPhotos - 1 ? 'primary' : 'secondary'}
        >
          {'SIGUIENTE'}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default PhotoViewer
