import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE_LOGIN } from '../../routes/routes-constants'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import styles from '../header/Header.module.css'
import { Avatar, Box, Grid, Stack } from '@mui/material'
import iconLogout from '../../assets/superior_menu/icon-logout.svg'
import { LoggedUser } from '../../modules/users/models/LoggedUser'
import { useNavigate } from 'react-router-dom'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const NavRight = () => {
  const { t } = useTranslation()
  const [listOpen, setListOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleSetMenuSelected = (menuSelected: string) => {
    navigate(menuSelected)
  }

  const handleLogout = () => {
    loggedUserService.logout()
    navigate(ROUTE_LOGIN)
  }

  function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
    }
  }

  function renderUserInfo(user: LoggedUser) {
    if (user && user.name) {
      return (
        <Grid container justifyContent="flex-end">
          <Stack direction={'row'} spacing={2} style={{ marginRight: 10 }}>
            <Box style={{ textAlign: 'right' }}>
              <Box style={{ fontWeight: 'bold' }}>{user?.name}</Box>
              <Box>{user?.email}</Box>
            </Box>
            <Avatar {...stringAvatar(user.name)} />
            <img style={{cursor: 'pointer'}} src={iconLogout} onClick={() => handleLogout()} />
          </Stack>
        </Grid>
      )
    }
    return <></>
  }

  let user = loggedUserService.get()
  return (user && renderUserInfo(user)) || <></>
}

export default NavRight
