import { AppRouter } from './routes/AppRouter'
import './App.css'

function App() {
  return (
    <>
      <AppRouter />
    </>
  )
}

export default App
