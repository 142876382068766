import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import { Toolbar } from '@mui/material'
import NavRight from '../header-component/NavRight'

const drawerWidth: string = '15%'

//TODO
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  backgroundImage:
    'linear-gradient(to right, var(--color-secundary-theme), #888888)',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  height: '75px',
  paddingTop: '9px',
  paddingLeft: '2px',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth})`,
}))

const CustomAppBar = () => {
  return (
    <>
      <AppBar>
        <Toolbar>
          <NavRight />
        </Toolbar>
      </AppBar>
    </>
  )
}

export default CustomAppBar
