import { v4 as uuidv4 } from 'uuid'
import { ImageType } from '../enums/ImageType'

export interface FileDTO {
  id: string
  issueID: string
  base64: string
  type: ImageType
  extension: string
  index: number
}

export class File {
  private _id: string
  private _issueID: string
  private _base64: string
  private _type: ImageType
  private _extension: string
  private _index: number

  constructor(p: FileDTO) {
    this._id = p.id
    this._issueID = p.issueID
    this._base64 = p.base64
    this._type = p.type
    this._extension = p.extension
    this._index = p.index
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get issueID(): string {
    return this._issueID
  }

  set issueID(value: string) {
    this._issueID = value
  }

  get base64(): string {
    return this._base64
  }

  set base64(value: string) {
    this._base64 = value
  }

  get type(): ImageType {
    return this._type
  }

  set type(value: ImageType) {
    this._type = value
  }

  get extension(): string {
    return this._extension
  }

  set extension(value: string) {
    this._extension = value
  }

  get index(): number {
    return this._index
  }

  set index(value: number) {
    this._index = value
  }
}

export function fromModel(c: File): FileDTO {
  return {
    id: c.id,
    issueID: c.issueID,
    base64: c.base64,
    type: c.type,
    extension: c.extension,
    index: c.index,
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}

export function emptyFileDTO(): FileDTO {
  return {
    id: uuidv4(),
    issueID: '',
    base64: '',
    type: ImageType.ISSUE_IMAGE,
    extension: '',
    index: NaN,
  }
}

export interface FileCount {
  [ImageType.ISSUE_IMAGE]: number
  [ImageType.EXECUTION_IMAGE]: number
  [ImageType.RESOLUTION_IMAGE]: number
}

export interface FileQuery {
  id: string
  ids: string[]
  date: Date
  issueID: string
  index: number
}
