import {
  Box,
  Button,
  ButtonProps, CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Option } from './CustomSelect'
import { Color } from 'react-bootstrap/types'

export type ColorType = 'primary' | 'secondary' | undefined
interface CustomButtonProps extends ButtonProps {
  color?: ColorType
  loading?: boolean
}

function CustomButton({ color, loading, disabled, children, ...rest }: CustomButtonProps) {
  function getColor(color: ColorType) {
    if (color === 'primary') return 'var(--color-orange-theme)'
    return 'var(--color-darkgray-theme)'
  }

  return (
    <Button
      disabled={loading || disabled}
      {...rest}
      style={{
        boxShadow: 'none',
        fontSize: 20,
        minWidth: 250,
        height: 45,
        borderRadius: 18,
        backgroundColor: getColor(color),
      }}
      variant={'contained'}
    >
      {loading && <CircularProgress size={'small'} style={{ color: 'white' }} />
      || children}
    </Button>
  )
}

export default CustomButton
