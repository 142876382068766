import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CustomSelect from '../../components/form/CustomSelect'
import { State } from '../../modules/issues/models/State'
import { getIssueContainer } from '../../container/issue-module'
import { IStateService } from '../../modules/issues/services/StateService'
import { STATE_SERVICE_KEY } from '../../modules/issues/container'
import { Issue } from '../../modules/issues/models/Issue'
import _ from 'lodash'
import removeIcon from '../../assets/remove.svg'
import DatePickerTheme from '../../components/datepicker/DatePickerTheme'

const stateService = getIssueContainer().get<IStateService>(STATE_SERVICE_KEY)

interface IssuesFilterProps {
  onFilterChange?: (filter: Filter) => void
  issues?: Issue[]
}

export interface Filter {
  since?: Date
  until?: Date
  state?: string
  district?: string
}

export function IssuesFilter({ issues, onFilterChange }: IssuesFilterProps) {
  const navigate = useNavigate()

  const [filter, setFilter] = useState<Filter>({})
  const [states, setStates] = useState<State[]>([])
  const [districts, setDistricts] = useState<string[]>([])

  useEffect(() => {
    stateService.getAll().subscribe((st) => st && setStates(st))
  }, [])

  useEffect(() => {
    issues && setDistricts(_.uniq(issues.map((i) => i.address.district)))
  }, [issues])

  useEffect(() => {
    filter && onFilterChange && onFilterChange(filter)
  }, [filter])

  function clearFilters() {
    setFilter({})
  }

  function handleFormChange(field: keyof Filter, value: any) {
    setFilter({ ...filter, [field]: value })
  }

  function isFilter(): boolean {
    return (
      filter !== undefined &&
      Object.keys(filter).length > 0 &&
      Object.values(filter).filter((v) => v !== undefined && v !== null && v.toString() !== '')
        .length > 0
    )
  }

  return (
    <Box display={'flex'} style={{ marginTop: 10 }}>
      <Stack
        display={'inline-flex'}
        alignItems={'center'}
        direction={'row'}
        spacing={2}
        style={{ marginRight: 10 }}
      >
        <Typography style={{ fontWeight: 'bold' }} variant="body1">
          Filtrar
        </Typography>

        <DatePickerTheme
          value={filter?.since}
          placeholder={'Desde'}
          onChange={(date) => handleFormChange('since', date)}
        />

        <Typography style={{ fontWeight: 'bold' }} variant="body1">
          -
        </Typography>

        <DatePickerTheme
          value={filter?.until}
          placeholder={'Hasta'}
          onChange={(date) => handleFormChange('until', date)}
        />

        <CustomSelect
          showLabel={false}
          id={'state'}
          placeholder={'Estado'}
          fullWidth={true}
          style={{ height: 35 }}
          value={filter?.state}
          onChangeOption={(value) => handleFormChange('state', value)}
          options={states?.map((it) => {
            return { value: it.id, label: it.name }
          })}
        />

        {isFilter() && (
          <img
            width={35}
            onClick={() => clearFilters()}
            src={removeIcon}
            style={{ width: '35px', cursor: 'pointer' }}
          />
        )}
      </Stack>
    </Box>
  )
}
