import React, { SetStateAction, useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Box } from '@mui/material'
import DataTable from '../../components/table/DataTable'
import { ROUTE_USERS, ROUTE_USERS_NEW } from '../../routes/routes-constants'
import DividerTheme, { DividerAction } from '../../components/divider/DividerTheme'
import iconAdd from '../../assets/add-grey.svg'
import { useNavigate } from 'react-router-dom'
import { User } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { Query, QueryParam } from '../../common/api/Query'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

interface UserView extends User {}

function userToView(u: User): UserView {
  return u
}

const getRowsPerPageOptions = (rows: number): number[] => {
  const options = [10, 25, 50, 100]
  return options.filter((n) => n <= rows)
}

const updateUsers = (
  page: number,
  rowsPerPage: number,
  setUsers: React.Dispatch<SetStateAction<UserView[]>>
) => {
  userService
    .getFilteredItems(
      new Query({
        query: [new QueryParam('NAME', '%%')],
        sort: [{ field: 'ID' }],
        pager: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
        },
      })
    )
    .subscribe((users) => {
      if (users) {
        setUsers(users.map(userToView))
      }
    })
}

const INIT_PAGE = 0
const DEFAULT_ROWS_PER_PAGE = 10

export function Users(props: RouteProps) {
  const title = props.title || ''
  const navigate = useNavigate()

  const [userCount, setUserCount] = useState(-1)
  const [users, setUsers] = useState<UserView[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [currentPage, setCurrentPage] = useState(INIT_PAGE)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newValue = parseInt(event.target.value)
    if (!isNaN(newValue)) {
      setRowsPerPage(newValue)
      setCurrentPage(INIT_PAGE)
    }
  }

  useEffect(() => {
    userService
      .getUserCount(
        new Query({
          query: [new QueryParam('NAME', '%%')],
        })
      )
      .subscribe((count) => setUserCount(count))
    updateUsers(INIT_PAGE, DEFAULT_ROWS_PER_PAGE, setUsers)
  }, [])

  useEffect(() => {
    updateUsers(currentPage, rowsPerPage, setUsers)
  }, [currentPage, rowsPerPage])

  const columns = [
    { id: 'firstName', name: 'Nombre', size: '10%' },
    { id: 'lastName', name: 'Apellidos', size: '10%' },
    { id: 'email', name: 'Email', size: '30%' },
    { id: 'roles', name: 'Rol', size: '30%' },
  ]

  function handleClick(row: any) {
    navigate(`${ROUTE_USERS}/edit/${row.id}`, { state: { id: row.id } })
  }

  let headerAction: DividerAction = {
    icon: iconAdd,
    onClick: () => {
      navigate(ROUTE_USERS_NEW)
    },
  }

  return (
    <Box>
      <DividerTheme title={title} action={headerAction} />
      <Box style={{ marginTop: 60 }}>
        <DataTable
          withActions={true}
          onRowClick={(row) => handleClick(row)}
          data={users}
          columns={columns}
          totalRows={userCount}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={getRowsPerPageOptions(userCount)}
          handleChangePage={handleChangePage}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  )
}
