import React, { SetStateAction, useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Avatar, Box, Menu, MenuItem, Stack } from '@mui/material'
import { Filter, IssuesFilter } from './IssuesFilter'
import DataTable from '../../components/table/DataTable'
import { ROUTE_ISSUES, ROUTE_ISSUES_NEW } from '../../routes/routes-constants'
import DividerTheme, { DividerAction } from '../../components/divider/DividerTheme'
import iconAdd from '../../assets/add-grey.svg'
import iconAvatar from '../../assets/images/avatar-2.jpg'
import { useNavigate } from 'react-router-dom'
import { getIssueContainer } from '../../container/issue-module'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import { Issue, IssueDTO } from '../../modules/issues/models/Issue'
import StateButton from '../../components/StateButton'
import dayjs from 'dayjs'
import iconMore from '../../assets/table/icon-more.svg'
import { Query } from 'common/api/Query'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

interface IssueView extends IssueDTO {
  stateView: JSX.Element
  userView: JSX.Element
  responsibleView: JSX.Element
  actionsView: JSX.Element
  districtView: string
  typeView: string
  addressView: string
  dateView: string
}

function RowActions({ id }: { id: string }) {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const loggedUser = loggedUserService.get()

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function showIssue() {
    navigate(`${ROUTE_ISSUES}/${id}`, { state: { id: id } })
  }

  function editIssue() {
    navigate(`${ROUTE_ISSUES}/edit/${id}`, { state: { id: id } })
  }

  function removeIssue() {
    issueService.delete(id).subscribe(() => {
      window.location.reload()
    })
  }

  return (
    <div>
      <img
        style={{ cursor: 'pointer' }}
        aria-controls={open ? 'basic-menu' : undefined}
        id="basic-button"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => handleClick(event)}
        src={iconMore}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={showIssue}>Ver</MenuItem>
        <MenuItem disabled={loggedUser?.roles.includes('worker')} onClick={editIssue}>
          Editar
        </MenuItem>
        <MenuItem disabled={loggedUser?.roles.includes('worker')} onClick={removeIssue}>
          Eliminar
        </MenuItem>
      </Menu>
    </div>
  )
}

function issueToView(i: Issue): IssueView {
  return {
    ...i.toDTO(),
    dateView: dayjs(i.date).format('DD/MM/YYYY'),
    stateView: <StateButton state={i.state} />,
    districtView: i.address.district,
    typeView: i.type.name,
    addressView: i.address.address,
    responsibleView: (
      <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
        {i.responsible.firstName ? (
          <>
            <Avatar alt={i.responsible.firstName} src={iconAvatar} />
            <label>{`${i.responsible.firstName} ${i.responsible.lastName}`}</label>
          </>
        ) : (
          <>
            <Avatar alt={'no asignado'} src={iconAvatar} style={{ filter: 'grayscale(1)' }} />
            <label>{'No asignado'}</label>
          </>
        )}
      </Stack>
    ),
    userView: (
      <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
        {i?.user.firstName ? (
          <>
            <Avatar alt={i.user.firstName} src={iconAvatar} />
            <label>{`${i.user.firstName} ${i.user.lastName}`}</label>
          </>
        ) : (
          <>
            <Avatar alt={i.email} src={iconAvatar} />
            <label>{i.email}</label>
          </>
        )}
      </Stack>
    ),
    actionsView: <RowActions id={i.id} />,
  }
}

const updateIssues = (
  page: number,
  rowsPerPage: number,
  setIssues: React.Dispatch<SetStateAction<Issue[]>>
) => {
  issueService
    .getFilteredList(
      new Query({
        pager: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
        },
      })
    )
    .subscribe((issues) => {
      if (issues) {
        setIssues(issues)
      }
    })
}

const getRowsPerPageOptions = (rows: number): number[] => {
  const options = [10, 25, 50, 100]
  return options.filter((n) => n <= rows)
}

export function Issues(props: RouteProps) {
  const title = props.title || ''
  const navigate = useNavigate()

  const INIT_PAGE = 0
  const DEFAULT_ROWS_PER_PAGE = 10

  const [issues, setIssues] = useState<IssueView[]>([])
  const [issueCount, setIssueCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(INIT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [rawIssues, setRawIssues] = useState<Issue[]>([])
  const [filteredIssues, setFilteredIssues] = useState<Issue[]>([])
  const [filter, setFilter] = useState<Filter>()

  useEffect(() => {
    issueService.getIssueCount().subscribe((count) => setIssueCount(count))
    updateIssues(INIT_PAGE, DEFAULT_ROWS_PER_PAGE, setRawIssues)
  }, [])

  useEffect(() => {
    rawIssues && setFilteredIssues(rawIssues)
  }, [rawIssues])

  useEffect(() => {
    filteredIssues && setIssues(filteredIssues.map((i) => issueToView(i)))
  }, [filteredIssues])

  useEffect(() => {
    doFilter()
  }, [filter])

  useEffect(() => {
    updateIssues(currentPage, rowsPerPage, setRawIssues)
  }, [currentPage, rowsPerPage])

  const columns = [
    { id: 'incNumber', name: 'Expediente', size: '10%' },
    { id: 'typeView', name: 'Tipo', size: '10%' },
    { id: 'addressView', name: 'Ubicación', size: '15%' },
    { id: 'dateView', name: 'Fecha de alta', size: '10%' },
    { id: 'responsibleView', name: 'Responsable', size: '15%' },
    { id: 'stateView', name: 'Estado', size: '20%' },
    { id: 'actionsView', name: '', size: '5%' },
  ]

  const normalizeDate = (date: Date): Date => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    return newDate
  }

  const isBeforeOrEqual = (date1: Date, date2: Date): boolean => {
    return normalizeDate(date1) <= normalizeDate(date2)
  }

  const isAfterOrEqual = (date1: Date, date2: Date): boolean => {
    return normalizeDate(date1) >= normalizeDate(date2)
  }

  function filterIssue(f: Filter, i: Issue) {
    let valid = true
    if (f.district) {
      valid = valid && i.address.district === f.district
    }
    if (f.state) {
      valid = valid && i.state.id === f.state
    }
    if (f.since) {
      valid = valid && isAfterOrEqual(i.date, f.since)
    }
    if (f.until) {
      valid = valid && isBeforeOrEqual(i.date, f.until)
    }

    return valid
  }

  function doFilter() {
    filter && setIssues(rawIssues.filter((i) => filterIssue(filter, i)).map((i) => issueToView(i)))
  }

  function handleFilter(filter: Filter) {
    setFilter(filter)
  }

  function handleClick(row: any) {
    navigate(`${ROUTE_ISSUES}/${row.id}`, { state: { id: row.id } })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newValue = parseInt(event.target.value)
    if (!isNaN(newValue)) {
      setRowsPerPage(newValue)
      setCurrentPage(INIT_PAGE)
    }
  }
  let headerAction: DividerAction = {
    icon: iconAdd,
    onClick: () => {
      navigate(ROUTE_ISSUES_NEW)
    },
  }

  return (
    <Box>
      <DividerTheme title={title} action={headerAction} />
      <Box style={{ width: '100%', marginTop: 20 }}>
        <IssuesFilter onFilterChange={(filter) => handleFilter(filter)} issues={rawIssues} />
      </Box>
      <Box style={{ marginTop: 60 }}>
        <DataTable
          withActions={false}
          onRowClick={(row) => handleClick(row)}
          data={issues}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={getRowsPerPageOptions(issueCount)}
          totalRows={issueCount}
          columns={columns}
          handleChangePage={handleChangePage}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  )
}
