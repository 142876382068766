import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import GoogleMapReact, { Coords } from 'google-map-react'
import { MapFilter, MapFilters } from './MapFilters'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import { Issue, IssueDTO } from '../../modules/issues/models/Issue'
import { AddressPickerDialog, AddressPickerDialogProps } from './AddressPickerDialog'
import { ViewIssueDialog } from './ViewIssueDialog'
import { Snackbar, SnackbarContent } from '@mui/material'
import { getIssueContainer } from '../../container/issue-module'
import { ROUTE_ISSUES_NEW } from '../../routes/routes-constants'
import { AddressDTO } from '../../modules/issues/models/Address'
import { useNavigate } from 'react-router-dom'
import { Query } from 'common/api/Query'
import { getUserContainer } from 'container/user-module'
import { IUserService, USER_SERVICE_KEY } from 'modules/users'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

export type MapProps = {
  setSelected: (st: string) => void
} & RouteProps

const Marker = ({
  id,
  icon,
  lat,
  lng,
  onClick,
}: {
  id: string
  icon: string
  lat: number
  lng: number
  onClick: (id: string) => void
}) => (
  <img
    style={{ cursor: 'pointer' }}
    onClick={(event) => {
      event.stopPropagation()
      onClick(id)
    }}
    src={`../../assets/${icon}`}
  />
)

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const LAYERS = [
  'https://cococheck.es/kmz/spain1.kml',
  'https://cococheck.es/kmz/spain2.kml',
  'https://cococheck.es/kmz/spain3.kml',
  'https://cococheck.es/kmz/spain4.kml',
  'https://cococheck.es/kmz/spain5.kml',
]

export function Map(props: MapProps) {
  const [issues, setIssues] = useState<Issue[]>([])
  const [filteredIssues, setFilteredIssues] = useState<Issue[]>([])
  const [newIssue, setNewIssue] = useState<AddressPickerDialogProps>()
  const [viewIssueId, setViewIssueId] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [orgID, setOrgID] = useState<string>('')
  const [isSpain, setIsSpain] = useState<boolean>()
  const [center, setCenter] = useState<Coords>({
    lat: 0,
    lng: 0,
  })
  const navigate = useNavigate()

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180)
  }

  const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var R = 6371
    var dLat = deg2rad(lat2 - lat1)
    var dLon = deg2rad(lon2 - lon1)
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }

  useEffect(() => {
    const spainCoordinates = {
      lat: 40.463667,
      lng: -3.74922,
    }

    userService.getOrgLocation().subscribe((orgnanization) => {
      let distance = getDistanceFromLatLonInKm(
        spainCoordinates.lat,
        spainCoordinates.lng,
        orgnanization.lat,
        orgnanization.lng
      )

      if (distance < 510) {
        setIsSpain(true)
      }
    })
  }, [])

  useEffect(() => {
    userService.getOrgLocation().subscribe((res: any) => {
      setCenter({
        lat: res.lat,
        lng: res.lng,
      })
      setOrgID(res.id)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    issueService.getFilteredList(new Query({})).subscribe((res) => {
      console.log(res)
      res && setIssues(res)
    })
  }, [])

  useEffect(() => {
    handleFilterChange([])
  }, [issues])

  const zoom: number = 7

  function handleFilterChange(filters: MapFilter[]) {
    const fi = issues.filter((issue) =>
      filters.length
        ? filters
            .map((af) => af.active && af.label.toUpperCase())
            .includes(issue.state.name.toUpperCase())
        : true
    )
    setFilteredIssues(fi)
  }

  function showCreateIssueDialog(lat: number, lng: number, district: string) {
    setNewIssue({ open: true, lat: lat, lng: lng, district: district })
  }

  function createIssue(address: AddressDTO | undefined, lat: number, lng: number) {
    const issue: IssueDTO = {
      description: '',
      responsibleID: '',
      stateID: '',
      typeID: '',
      userID: '',
      workflowID: '',
      createdAt: new Date(),
      id: '',
      addressID: '',
      lat: lat,
      lng: lng,
      incNumber: '',
      phone: '',
      email: '',
      orgID: orgID,
    }
    navigate(ROUTE_ISSUES_NEW, { state: { issue: issue, address: address } })
  }

  function viewIssue(id: string) {
    setViewIssueId(id)
  }

  function getMarkers() {
    return filteredIssues.map((issue, i) => (
      <Marker
        onClick={viewIssue}
        key={issue.id}
        id={issue.id}
        lat={issue.lat}
        lng={issue.lng}
        icon={issue.state.icon}
      />
    ))
  }

  const onMapLoad = (mapInstance: any) => {
    if (isSpain) {
      LAYERS.forEach((kmz) => {
        const l = new google.maps.KmlLayer({
          url: kmz,
          clickable: true,
          preserveViewport: true,
          suppressInfoWindows: true,
        })
        l.addListener('click', (kmlEvent: any) => {
          const district = kmlEvent.featureData?.description
          showCreateIssueDialog(kmlEvent.latLng.lat, kmlEvent.latLng.lng, district)
        })
        l.setMap(mapInstance)
      })
    }
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 0,
        width: '85%',
        height: 'calc(100% - 75px)',
      }}
    >
      {!isLoading && (
        <>
          <div
            style={{
              position: 'absolute',
              zIndex: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <GoogleMapReact
              onGoogleApiLoaded={(maps) => onMapLoad(maps.map)}
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || '' }}
              defaultCenter={center}
              defaultZoom={zoom}
            >
              {getMarkers()}
            </GoogleMapReact>
          </div>
          <div
            style={{
              zIndex: 1,
              position: 'absolute',
              top: 30,
              left: 10,
            }}
          >
            {isSpain && (
              <Snackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                open={true}
                onClose={() => {}}
              >
                <SnackbarContent message="Pulse sobre el mapa para crear una incidencia" />
              </Snackbar>
            )}
            <MapFilters onFilterChange={handleFilterChange} />
            {newIssue && (
              <AddressPickerDialog
                onClose={() => setNewIssue(undefined)}
                open={newIssue.open}
                lat={newIssue.lat}
                title={'Nueva Incidencia'}
                lng={newIssue.lng}
                district={newIssue.district}
                successLabel={'CREAR'}
                onSuccess={(address, lat, lng) => createIssue(address, lat, lng)}
              />
            )}
            {viewIssueId && (
              <ViewIssueDialog
                onClose={() => setViewIssueId(undefined)}
                open={true}
                issueId={viewIssueId}
                setSelected={props.setSelected}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
