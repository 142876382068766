import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { map } from 'rxjs/operators'
import { WorklogQuery, toModel } from '../models/Worklog'
import { WorklogApi } from '../api/WorklogApi'
import { Worklog, WorklogDTO } from '../models/Worklog'
import { Query } from '../../../common/api/Query'

type Props = {
  apiKey: symbol
}

export interface IWorklogService extends IInit {
  getByID(id: string): Observable<Worklog>

  getAll(): Observable<Worklog[] | undefined>

  add(e: WorklogDTO): Observable<Worklog | undefined>

  update(e: WorklogDTO): Observable<Worklog | undefined>

  getFilteredList(q: Query<WorklogQuery>): Observable<WorklogDTO[]>

  delete(id: string): Observable<boolean>
}

export class WorklogService implements IWorklogService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: WorklogApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<WorklogApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  add(e: WorklogDTO): Observable<Worklog | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<Worklog> {
    return this._api.getByID(id)
  }

  update(e: WorklogDTO): Observable<Worklog | undefined> {
    return this._api.update(e)
  }

  getAll(): Observable<Worklog[] | undefined> {
    let all = this._api.getAll()
    return all.pipe(map((res) => res?.items.map((i) => toModel(i))))
  }

  getFilteredList(q: Query<WorklogQuery>): Observable<WorklogDTO[]> {
    return this._api.getFilteredList(q)
  }
}
