import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { Toolbar, Divider } from '@mui/material'
import ListItems from './ListItems'
import styles from '../header/Header.module.css'

type CustomDrawerProps = {
  selected: string
  handleChangeSelected: (st: string) => void
}

const drawerWidth: string = '15%'

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#888888',
    padding: '8px 8px 0px',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(11),
      },
    }),
  },
}))

const CustomDrawer = (props: CustomDrawerProps) => {
  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar
          className="toolBar"
          sx={{
            px: [1],
          }}
        >
          <div className={styles.drawerBox}>
            <img
              src={require('../../assets/images/logo.png').default}
              alt="Logo Workit"
              className={styles.drawerLogo}
            />
          </div>
        </Toolbar>
        <Divider />
        <ListItems selected={props.selected} handleChangeSelected={props.handleChangeSelected} />
      </Drawer>
    </>
  )
}

export default CustomDrawer
