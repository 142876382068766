import * as React from 'react'
import { ReactElement, useEffect, useState } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { withStyles } from '@material-ui/core'

const gray = '#E8E8E8'

export interface DatePickerThemeProps {
  placeholder?: string
  value?: Date | null
  onChange?: (date: Date | null) => void
}

export default function DatePickerTheme({
  value,
  placeholder,
  onChange,
}: DatePickerThemeProps): ReactElement {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  useEffect(() => {
    setSelectedDate(value || null)
  }, [value])

  useEffect(() => {
    value !== selectedDate && onChange && onChange((selectedDate && new Date(selectedDate)) || null)
  }, [selectedDate])

  const CustomTextField = withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
      },
    },
  })(TextField)

  function getParams(params: TextFieldProps): TextFieldProps {
    const newInputProps = { ...params.inputProps, placeholder: placeholder }
    const newParams = { ...params, inputProps: newInputProps }
    return newParams
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={selectedDate}
        inputFormat={'DD/MM/YYYY'}
        onChange={(newValue) => {
          setSelectedDate(newValue)
        }}
        renderInput={(params) => (
          <>
            {/**@ts-ignore */}
            <CustomTextField
              fullWidth={true}
              variant={'outlined'}
              InputLabelProps={{
                shrink: true,
              }}
              {...getParams(params)}
              InputProps={{
                placeholder: 'Desde',
                disableUnderline: true,
                endAdornment: params.InputProps?.endAdornment,
                style: {
                  border: 'none',
                  backgroundColor: gray,
                  borderRadius: 20,
                  height: 35,
                },
              }}
              value={selectedDate}
            />
          </>
        )}
      />
    </LocalizationProvider>
  )
}
