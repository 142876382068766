import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Box, Grid } from '@mui/material'
import DividerTheme from '../../components/divider/DividerTheme'
import CustomTextField from '../../components/form/CustomTextField'
import CustomButton from '../../components/form/CustomButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_ISSUES, ROUTE_USERS } from '../../routes/routes-constants'
import { UserDTO } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { ROLES_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { UserService } from '../../modules/users/services/UserService'
import { v4 as uuidv4 } from 'uuid'
import { Role } from '../../modules/users/models/Role'
import { RolesService } from '../../modules/users/services/RolesServices'
import CustomSelect from '../../components/form/CustomSelect'

export type UserEditProps = {
  id?: string
} & RouteProps

const usersService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const rolesService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)

export function UserEdit(props: UserEditProps) {
  const title = props.title || ''
  const navigate = useNavigate()
  const location = useLocation()

  const [user, setUser] = React.useState<UserDTO>()
  const [repeatPassword, setRepeatPassword] = React.useState<string>('')

  const [roles, setRoles] = React.useState<Role[]>([])

  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Map<keyof UserDTO | 'repeatPassword', string>>(
    new Map()
  )

  React.useEffect(() => {
    if (props.id) {
      usersService.getByID(props.id).subscribe((u) => u && setUser(u.toDTO()))
    }
  }, [props.id])

  React.useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        usersService.getByID(location.state.id).subscribe((u) => u && setUser(u.toDTO()))
      }
    }
    rolesService.getAll().subscribe((r) => r && setRoles(r))
  }, [])

  function goBack() {
    navigate(ROUTE_USERS)
  }

  function handleFormChange(field: keyof UserDTO, value: any) {
    if (field === 'roles') {
      value = [value]
    }
    //@ts-ignore
    setUser({ ...user, [field]: value })
  }

  function isFormValid(): boolean {
    const err: Map<keyof UserDTO | 'repeatPassword', string> = new Map()
    if (!user?.firstName) err.set('firstName', 'Indique el nombre del usuario')
    if (!user?.lastName) err.set('lastName', 'Indique los apellidos del usuario')
    if (!user?.email) err.set('email', 'El email es obligatorio')
    if (user?.password !== repeatPassword) {
      err.set('password', 'Las contraseñas no coindicen')
      err.set('repeatPassword', 'Las contraseñas no coindicen')
    }

    const valid: boolean = err.size === 0
    setErrors(err)
    return valid
  }

  function saveUser(user: UserDTO, cb?: (user?: UserDTO) => void) {
    if (user.id) {
      usersService.update(user).subscribe((u) => cb && cb(u))
    } else {
      usersService.add({ ...user, id: uuidv4() }).subscribe((u) => cb && cb(u))
    }
  }

  function save() {
    if (user && isFormValid()) {
      setLoading(true)
      saveUser(user, (u) => {
        setLoading(false)
        navigate(ROUTE_USERS)
      })
    }
  }

  return (
    <Box>
      <DividerTheme title={title.toUpperCase()} />
      <Box style={{ marginTop: 20 }}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth={true}
              errorLabel={errors.get('firstName')}
              onChange={(e) => handleFormChange('firstName', e.target.value)}
              value={user?.firstName}
              label={'Nombre'}
              labelColor="black"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth={true}
              errorLabel={errors.get('lastName')}
              onChange={(e) => handleFormChange('lastName', e.target.value)}
              value={user?.lastName}
              label={'Apellidos'}
              labelColor="black"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth={true}
              errorLabel={errors.get('email')}
              onChange={(e) => handleFormChange('email', e.target.value)}
              value={user?.email}
              type={'email'}
              label={'Email'}
              labelColor="black"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSelect
              label={'Rol'}
              id={'roles'}
              emptyOption={!user?.id}
              errorLabel={errors.get('roles')}
              onChangeOption={(value) => handleFormChange('roles', value)}
              value={roles && roles[0]}
              options={roles?.map((r) => {
                return { value: r.name, label: r.name }
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth={true}
              errorLabel={errors.get('password')}
              type={'password'}
              onChange={(e) => handleFormChange('password', e.target.value)}
              value={user?.password}
              label={'Contraseña'}
              labelColor="black"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              fullWidth={true}
              type={'password'}
              errorLabel={errors.get('repeatPassword')}
              onChange={(e) => setRepeatPassword(e.target.value)}
              value={repeatPassword}
              label={'Repetir contraseña'}
              labelColor="black"
            />
          </Grid>
        </Grid>
        <Box style={{ marginTop: 60 }} display={'flex'} justifyContent={'space-between'}>
          <CustomButton loading={loading} onClick={() => save()} color={'primary'}>
            {'GUARDAR'}
          </CustomButton>
          <CustomButton onClick={() => goBack()}>{'CANCELAR'}</CustomButton>
        </Box>
      </Box>
    </Box>
  )
}
