import DividerTheme from '../../components/divider/DividerTheme'
import DataTable from '../../components/table/DataTable'
import iconAddGrey from '../../assets/add-grey.svg'
import { Avatar, Grid, Stack } from '@mui/material'
import React, { SetStateAction, useEffect, useState } from 'react'
import { ROUTE_WORKNOTES, ROUTE_WORKNOTES_EDIT } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Worknote, WorknoteDTO } from '../../modules/worknotes/models/Worknote'
import dayjs from 'dayjs'
import iconAvatar from '../../assets/images/avatar-2.jpg'
import { getIssueContainer } from '../../container/issue-module'
import { IWorknoteService } from '../../modules/worknotes'
import { WORKNOTE_SERVICE_KEY } from '../../modules/worknotes/container'
import { Query } from '../../common/api/Query'
import { worknoteStates } from 'modules/worknotes/enums/WorknoteState'
import { User } from 'modules/users/models/User'
import { getUserContainer } from 'container/user-module'
import { UserService } from 'modules/users/services/UserService'
import { USER_SERVICE_KEY } from 'modules/users'

const worknoteService = getIssueContainer().get<IWorknoteService>(WORKNOTE_SERVICE_KEY)

interface WorknotesTableProps {
  issueID: string
}

interface WorknoteView extends WorknoteDTO {
  userView: JSX.Element
  titleView: string
  dateView: string
  finishedView: string
}

const worknoteColumns = [
  { id: 'titleView', name: 'Descripción', size: '40%' },
  { id: 'dateView', name: 'Fecha', size: '15%' },
  { id: 'userView', name: 'Operario', size: '35%' },
  { id: 'finishedView', name: 'Estado', size: '10%' },
]

function worknoteToView(w: Worknote, users: User[]): WorknoteView {
  console.log('users: ', users)
  console.log('busco: ', w.userID)
  let usersfiltered = users.filter((u) => u.id === w.userID)
  console.log('filtered: ', usersfiltered)
  let user = usersfiltered?.length > 0 ? usersfiltered[0] : undefined

  const wn = {
    id: w.id,
    userID: w.userID,
    date: w.date,
    issue_id: w.issue_id,
    description: w.description,
    remarks: w.remarks,
    lat: w.lat,
    lng: w.lng,
    address: w.address,
    state: w.state,
    titleView: w.description,
    dateView: dayjs(w.date).format('DD/MM/YYYY'),
    userView: (Object.keys(w.userID).length > 0 && (
      <Stack direction={'row'} spacing={2}>
        <Avatar alt={user?.firstName + ' ' + user?.lastName} src={iconAvatar} />
        <label>{`${user?.firstName} ${user?.lastName}`}</label>
      </Stack>
    )) || <></>,
    //TODO BEA
    finishedView: worknoteStates()[w.state],
  }
  return wn
}

const INIT_PAGE = 0
const DEFAULT_ROWS_PER_PAGE = 5

const updateWorknotes = (
  id: string,
  page: number,
  rowsPerPage: number,
  setWorknotes: React.Dispatch<SetStateAction<WorknoteView[]>>,
  users: User[]
) => {
  worknoteService
    .getPaginatedByID(
      id,
      new Query({
        pager: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
        },
      })
    )
    .subscribe((worknotes) => {
      if (worknotes) {
        setWorknotes(worknotes.map((w) => worknoteToView(w, users)))
      }
    })
}

const usersService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export function WorknotesTable({ issueID }: WorknotesTableProps) {
  const navigate = useNavigate()
  const [worknoteCount, setWorknoteCount] = useState(-1)
  const [currentPage, setCurrentPage] = useState(INIT_PAGE)
  const [worknotes, setWorknotes] = useState<WorknoteView[]>([])
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    usersService.getAll().subscribe((users) => users && setUsers(users))
  }, [])

  useEffect(() => {
    worknoteService.getCountByID(issueID).subscribe((count) => setWorknoteCount(count))
    updateWorknotes(issueID, INIT_PAGE, DEFAULT_ROWS_PER_PAGE, setWorknotes, users)
  }, [])

  useEffect(() => {
    updateWorknotes(issueID, currentPage, DEFAULT_ROWS_PER_PAGE, setWorknotes, users)
  }, [currentPage, users])

  function addWorknote(id: string) {
    navigate(`${ROUTE_WORKNOTES_EDIT}/${id}`, { state: { id: id } })
  }

  function editWorknote(row: Worknote) {
    navigate(`${ROUTE_WORKNOTES}/${row.id}`, { state: { id: row.id } })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage)
  }

  return (
    <Grid item xs={6} mb={5}>
      <DividerTheme
        action={{
          icon: iconAddGrey,
          iconHeight: 20,
          iconWidth: 20,
          onClick: () => addWorknote(issueID),
        }}
        fontSize={17}
        fontColor={'var(--color-orange-theme)'}
        title={'Partes de Trabajo'}
      />
      <DataTable
        onRowClick={(row) => editWorknote(row)}
        data={worknotes}
        columns={worknoteColumns}
        totalRows={worknoteCount}
        currentPage={currentPage}
        rowsPerPage={DEFAULT_ROWS_PER_PAGE}
        rowsPerPageOptions={[]}
        handleChangePage={handleChangePage}
      />
    </Grid>
  )
}
