import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of, pipe } from 'rxjs'
import { FileContainerConfig } from '../container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { File, FileDTO, toModel, FileQuery, FileCount } from '../models/File'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import * as url from 'url'
import { ImageType } from '../enums/ImageType'
import { defaultTo } from 'lodash'

export interface IFileApi extends IInit {
  getByID(id: string): Observable<File | undefined>

  getFilteredList(q: Query<File>): Observable<ItemList<File>>

  getCountByType(q: Query<File>): Observable<FileCount>

  add(e: FileDTO): Observable<File | undefined>

  update(e: FileDTO): Observable<File | undefined>

  delete(id: string): Observable<boolean>
}

export class FileApi implements IFileApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as FileContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: `${this._url}/files/${id}` }).pipe(
      map<FileDTO, File>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<File>): Observable<ItemList<File>> {
    return this._httpClient.get<ItemList<File>>({ url: prepareURL(this._url + '/files', q) }).pipe(
      map<ItemList<FileDTO>, ItemList<File>>((dto) => {
        const itemList = emptyList<File>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<File>())
      })
    )
  }

  getCountByType(q: Query<File>): Observable<FileCount> {
    const countByType: FileCount = {
      [ImageType.ISSUE_IMAGE]: 0,
      [ImageType.EXECUTION_IMAGE]: 0,
      [ImageType.RESOLUTION_IMAGE]: 0,
    }

    return this._httpClient
      .get<[{ type: ImageType; count: string }]>({
        url: prepareURL(this._url + '/fileCount', q),
      })
      .pipe(
        map((res) => {
          res.forEach((item) => {
            countByType[item.type] = parseInt(item.count)
          })

          return countByType
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of({
            [ImageType.ISSUE_IMAGE]: 0,
            [ImageType.EXECUTION_IMAGE]: 0,
            [ImageType.RESOLUTION_IMAGE]: 0,
          })
        })
      )
  }

  add(e: FileDTO): Observable<File | undefined> {
    return this._httpClient.post<File>({ url: this._url + '/files', body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: FileDTO): Observable<File | undefined> {
    return this._httpClient.put<File>({ url: this._url + '/files', body: e }).pipe(
      map<FileDTO, File>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/files/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
