import React, { useEffect, useRef, useState } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { CustomTextFieldProps } from '../form/CustomTextField'
import { Box, FormControl, Stack } from '@mui/material'
import style from './AddressAutocomplete.module.css'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { getUserContainer } from 'container/user-module'
import { IUserService, USER_SERVICE_KEY } from 'modules/users'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const libraries: Libraries = ['places']

interface AutocompleteProps extends CustomTextFieldProps {
  value?: string | number | readonly string[] | undefined
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void
}

const gray = '#E8E8E8'

const AddressAutocomplete: React.FC<AutocompleteProps> = ({
  value,
  icon,
  containerStyle,
  label,
  errorLabel,
  onPlaceSelect,
  onChange,
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [orgnanization, setOrganization] = useState<any>()

  useEffect(() => {
    userService.getOrgLocation().subscribe((res) => {
      setOrganization(res)
    })
  }, [])

  const handlePlaceSelect = () => {
    const autocomplete = autocompleteRef.current

    if (autocomplete) {
      const place = autocomplete.getPlace()
      onPlaceSelect(place)
    }
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries,
  })

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180)
  }

  const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var R = 6371
    var dLat = deg2rad(lat2 - lat1)
    var dLon = deg2rad(lon2 - lon1)
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }

  useEffect(() => {
    if (!orgnanization) return

    if (isLoaded && !loadError) {
      //bounds Santiago de Chile
      let bounds: google.maps.LatLngBoundsLiteral = {
        north: -33.27642178480649,
        south: -33.67112945875235,
        east: -70.42954555120299,
        west: -70.83286480993999,
      }

      const spainCoordinates = {
        lat: 40.463667,
        lng: -3.74922,
      }

      let distance = getDistanceFromLatLonInKm(
        spainCoordinates.lat,
        spainCoordinates.lng,
        orgnanization.lat,
        orgnanization.lng
      )

      if (distance < 510) {
        bounds = {
          north: 37.70279,
          south: 37.545062,
          east: -0.953166,
          west: -1.042132,
        }
      }

      const options: google.maps.places.AutocompleteOptions = {
        bounds: new google.maps.LatLngBounds(bounds),
      }
      autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current!, options)
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect)
    }
  }, [isLoaded, loadError, orgnanization])

  if (loadError) {
    return <div>Error al cargar el mapa</div>
  }

  if (!isLoaded) {
    return <div>Cargando el mapa...</div>
  }

  return (
    <FormControl style={{ ...containerStyle }} fullWidth={true}>
      <label style={{ marginBottom: 5, fontWeight: 'bold', color: 'black' }}>{label}</label>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Stack style={{ width: '100%' }} direction={'row'}>
          <input
            ref={inputRef}
            type="text"
            value={value}
            style={{
              width: '100%',
              height: 50,
              backgroundColor: gray,
              borderRadius: 20,
              borderWidth: 0,
              padding: 8,
            }}
            className={style.pacContainer}
            onChange={onChange}
            placeholder="Ingresa una dirección"
          />
          {icon && (
            <img
              src={icon.icon}
              onClick={icon.onClick}
              style={{ marginLeft: 20, cursor: 'pointer' }}
            />
          )}
        </Stack>
      </Box>
      {errorLabel && (
        <Box>
          <label style={{ color: 'red' }}>{errorLabel}</label>
        </Box>
      )}
    </FormControl>
  )
}

export default AddressAutocomplete
