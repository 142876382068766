// @ts-nocheck

import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'

type PDFViewerProps = {
  file: string
}

export default function PDFViewer(props: PDFViewerProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  document.addEventListener('contextmenu', (event) => {
    event.preventDefault()
  })

  function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
    setNumPages(pdf.numPages)
    setPageNumber(1)
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <>
      <div className="main">
        <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <div>
          <div className="pagec">
            Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
          </div>
          <div className="buttonc">
            <button type="button" disabled={pageNumber <= 1} onClick={previousPage} className="Pre">
              Previous
            </button>
            <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
