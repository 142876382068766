import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import { LoggedUser } from 'modules/users/models/LoggedUser'
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import CustomButton from './components/form/CustomButton'
import logo from 'assets/left_menu/logo.svg'
import CustomTextField from './components/form/CustomTextField'
import { useNavigate } from 'react-router-dom'
import { ROUTE_LOGIN } from './routes/routes-constants'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export type RecoveryPasswordProps = {}

export default function RecoveryPassword(props: RecoveryPasswordProps) {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [hasError, setHasError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  const handlerLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doRecover()
  }

  function handleDialogClose() {
    setOpen(false)
  }

  function successDialog() {
    return (
      <Dialog
        open={open}
        onClose={() => handleDialogClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Cambio de Contraseña'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hemos enviado un email a {email} con las instrucciones para realizar el cambio de
            contraseña.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => navigate(ROUTE_LOGIN)} color={'primary'}>
            {'CERRAR'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    )
  }

  const doRecover = () => {
    setHasError(false)
    setLoading(true)
    loggedUserService.passwordRecover(email).subscribe((res) => {
      setLoading(false)
      setOpen(true)
    })
  }

  return (
    <div className={styles.background}>
      <Container component="div" className={styles.mainContainer}>
        {successDialog()}
        <div className={styles.container}>
          <img src={require("./assets/images/logo.png").default} alt='Logo Workit' className={styles.logo} />
          <form className={styles.recoverFormContainer} noValidate onSubmit={handlerLogin}>
            <CustomTextField
              fullWidth={true}
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              label={'Email'}
            />

            <Box display={'flex'} className={styles.button}>
              <CustomButton
                loading={loading}
                fullWidth={true}
                onClick={() => doRecover()}
                color={'secondary'}
              >
                {'RECUPERAR'}
              </CustomButton>
            </Box>
            {hasError && (
              <Alert style={{ marginTop: 20 }} severity="error">
                Email no válido
              </Alert>
            )}
            {success && (
              <Alert style={{ marginTop: 20 }} severity="success">
                Cambio realizado correctamente
              </Alert>
            )}
          </form>
        </div>
      </Container>
    </div>
  )
}
