import { v4 as uuidv4 } from 'uuid'

export interface WorklogDTO {
  id: string
  worknoteID: string
  date: Date
  description: string
  time: number
}

export function emptyWorklogDTO(worknoteID: string) {
  return {
    id: uuidv4(),
    worknoteID: worknoteID,
    date: new Date(),
    description: '',
    time: 0,
  }
}

export class Worklog {
  private readonly _id: string
  private readonly _worknoteID: string
  private readonly _date: Date
  private readonly _description: string
  private readonly _time: number

  constructor(p: WorklogDTO) {
    this._id = p.id || uuidv4()
    this._worknoteID = p.worknoteID
    this._date = p.date
    this._description = p.description
    this._time = p.time
  }

  get id(): string {
    return this._id
  }

  get worknoteID(): string {
    return this._worknoteID
  }

  get date(): Date {
    return this._date
  }

  get description(): string {
    return this._description
  }

  get time(): number {
    return this._time
  }

  toDTO(): WorklogDTO {
    return {
      id: this.id,
      worknoteID: this.worknoteID,
      date: this.date,
      description: this.description,
      time: this.time,
    }
  }
}

export function toModel(d: WorklogDTO): Worklog {
  return new Worklog(d)
}

export function fromModel(d: Worklog): WorklogDTO {
  return {
    id: d.id,
    worknoteID: d.worknoteID,
    date: d.date,
    description: d.description,
    time: d.time,
  }
}

export interface WorklogQuery {
  worknoteID: string
}
