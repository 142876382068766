import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import CustomButton from '../../components/form/CustomButton'
import { useNavigate } from 'react-router-dom'
import { AddressDTO } from '../../modules/issues/models/Address'
import { AddressPicker } from './AddressPicker'

export type AddressPickerDialogProps = {
  open: boolean,
  lat?: number,
  lng?: number,
  district?: string,
  title?: string,
  onSuccess?: (address: AddressDTO | undefined, lat: number, lng: number) => void
  successLabel?: string
  onClose?: () => void
}

export function AddressPickerDialog(props: AddressPickerDialogProps) {
  const navigate = useNavigate()

  const [lat, setLat] = useState<number>(0)
  const [lng, setLng] = useState<number>(0)
  const [district, setDistrict] = useState<string>('')

  const [address, setAddress] = useState<AddressDTO>()
  const [loading, setLoading] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(props.open)
    props.lat && setLat(props.lat)
    props.lng && setLng(props.lng)
    props.district && setDistrict(props.district)
  }, [props])

  function handleClose() {
    setOpen(false)
    props.onClose && props.onClose()
  }

  function handleSuccess() {
    props.onSuccess && props.onSuccess(address, lat, lng)
  }

  return (<Dialog open={open} fullWidth={true} maxWidth={'md'} onClose={handleClose}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <AddressPicker lat={lat} lng={lng} district={district} onAddressChange={(address) => setAddress(address)} />
    </DialogContent>
    <DialogActions>
      {props.successLabel &&
      <CustomButton onClick={() => handleSuccess()} color={'primary'}>{props.successLabel}</CustomButton>}
      <CustomButton onClick={() => handleClose()}>{'CANCELAR'}</CustomButton>
    </DialogActions>
  </Dialog>)
}
