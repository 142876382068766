import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  Input,
  InputProps,
  Stack,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import iconAdd from '../../assets/icon-add.svg'
import { makeStyles } from '@material-ui/core'
import removeIcon from '../../assets/remove.svg'
import { render } from '@testing-library/react'

interface CustomInputFileProps extends ButtonProps {
  label?: string
  errorLabel?: string
  base64File?: Base64File
  onFileRead?: (file?: Base64File) => void
}

interface Base64File {
  name: string
  base64: string
}

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

const useStyles = makeStyles({
  root: {},
  rootAbsolute: {
    position: 'relative',

    '& .MuiButton-sizeSmall': {
      position: 'absolute',
      left: 16,
    },
    '& .MuiButton-startIcon': {
      position: 'absolute',
      left: 16,
    },
  },
  rootNegativeMargin: {
    '& .MuiButton-startIcon': {
      marginLeft: -46,
    },
  },
})

const gray = '#E8E8E8'

function CustomInputFile({
  label,
  base64File,
  onFileRead,
  errorLabel,
  children,
  ...rest
}: CustomInputFileProps) {
  const classes = useStyles()

  const inputRef = useRef<any>(null)
  const [file, setFile] = useState<Base64File>()

  useEffect(() => {
    onFileRead && onFileRead(file)
  }, [file])

  useEffect(() => {
    !file && setFile(base64File)
  }, [base64File])

  const resetFileInput = () => {
    setFile(undefined)
    if (inputRef && inputRef.current) {
      inputRef.current.value = null
    }
  }

  const readFileBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string)
        }
        reject('No file')
      }
      reader.onerror = (error) => reject(error)
    })
  }

  const handleFileRead = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length <= 0) {
      return
    }

    const file = e.target.files[0]
    const fileBase64 = await readFileBase64(file)

    if (file.size > MAX_FILE_SIZE) {
      alert(`Tamaño máximo del archivo ${MAX_FILE_SIZE / (1024 * 1024)}MB`)
    } else {
      setFile({ name: file.name, base64: fileBase64 })
    }
    resetFileInput()
  }

  return (
    <FormControl fullWidth={true}>
      <label style={{ marginBottom: 5, fontWeight: 'bold', color: 'black' }}>{label}</label>
      <Box display={'inline-flex'} justifyContent={'flex-start'}>
        <Button
          style={{
            textTransform: 'none',
            color: 'white',
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            backgroundColor: 'black',
            width: 200,
            borderRadius: 20,
            borderWidth: 0,
            padding: 8,
          }}
          variant="contained"
          component="label"
        >
          <Typography variant={'button'} align={'left'} style={{ fontSize: 16, paddingRight: 10 }}>
            {'Añadir imagen'}
          </Typography>
          <img src={iconAdd} style={{ position: 'absolute', right: 10 }} />
          <input
            ref={inputRef}
            type="file"
            accept={'image/png, image/jpeg, image/jpg'}
            onChange={handleFileRead}
            hidden
          />
          {children}
        </Button>
      </Box>
      {errorLabel && (
        <Box>
          <label style={{ color: 'red' }}>{errorLabel}</label>
        </Box>
      )}
    </FormControl>
  )
}

export default CustomInputFile
