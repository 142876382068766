import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import CustomTextField from '../../components/form/CustomTextField'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ISSUES_NEW } from '../../routes/routes-constants'
import { IssueDTO } from '../../modules/issues/models/Issue'
import { AddressDTO, parseAddress } from '../../modules/issues/models/Address'

export type AddressPickerProps = {
  lat?: number
  lng?: number
  district?: string
  onAddressChange?: (address: AddressDTO | undefined) => void
}

export function AddressPicker(props: AddressPickerProps) {
  const geocoder = new google.maps.Geocoder()
  const navigate = useNavigate()
  const [lat, setLat] = useState<number>(0)
  const [lng, setLng] = useState<number>(0)
  const [district, setDistrict] = useState<string>('')
  const [address, setAddress] = useState<AddressDTO>()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    props.lat && setLat(props.lat)
    props.lng && setLng(props.lng)
    props.district && setDistrict(props.district)
  }, [props])

  useEffect(() => {
    props.onAddressChange && props.onAddressChange(address)
  }, [address])

  useEffect(() => {
    reverseGeocoding()
  }, [lat, lng])

  function reverseGeocoding() {
    const latlng: google.maps.LatLngLiteral = { lat: lat, lng: lng }

    geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          const addr = parseAddress(district, response.results[0].address_components, lat, lng)
          setAddress(addr)
        } else {
          console.warn('No results found')
        }
      })
      .catch((e) => console.error('Geocoder failed due to: ' + e))
  }

  function streetViewImage() {
    return (
      'https://maps.googleapis.com/maps/api/streetview?size=400x400&location=' +
      lat +
      ',' +
      lng +
      '&fov=80&heading=70&pitch=0&key=' +
      (process.env.REACT_APP_GOOGLE_API_KEY || '')
    )
  }

  function createIssue() {
    const issue: IssueDTO = {
      description: '',
      responsibleID: '',
      stateID: '',
      typeID: '',
      userID: '',
      workflowID: '',
      createdAt: new Date(),
      id: '',
      addressID: '',
      lat: lat,
      lng: lng,
      incNumber: '',
      phone: '',
      email: '',
      orgID: '',
    }
    navigate(ROUTE_ISSUES_NEW, { state: { issue: issue, address: address } })
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Grid item xs={12}>
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={lat}
            fullWidth={true}
            label={'Latitud'}
            labelColor="black"
          />
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={lng}
            fullWidth={true}
            label={'Longitud'}
            labelColor="black"
          />
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={address?.zipcode}
            fullWidth={true}
            label={'Código Postal'}
            labelColor="black"
          />
          <CustomTextField
            containerStyle={{ marginTop: 10 }}
            value={address?.address}
            fullWidth={true}
            label={'Dirección'}
            labelColor="black"
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {lat && lng ? (
          <Box sx={{ borderRadius: 20 }}>
            <img src={streetViewImage()} />
          </Box>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}
