export enum WorknoteState {
  Pending = 1,
  InProgess,
  Finished,
}

export const worknoteStates = (): Record<WorknoteState, string> => ({
  [WorknoteState.Pending]: 'Pendiente',
  [WorknoteState.InProgess]: 'En curso',
  [WorknoteState.Finished]: 'Finalizado',
})
