import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { map } from 'rxjs/operators'
import { IssueType, IssueTypeDTO, toModel } from '../models/IssueType'
import { IIssueTypeApi } from '../api/IssueTypeApi'
import { IIssueHistoryApi } from '../api/IssueHistoryApi'
import { IssueHistory, IssueHistoryDTO, IssueHistoryQuery } from '../models/IssueHistory'
import { Query } from '../../../common/api/Query'

type Props = {
  apiKey: symbol
}

export interface IIssueHistoryService extends IInit {
  getByID(id: string): Observable<IssueHistory | undefined>

  getByIssueID(id: string): Observable<IssueHistory[] | undefined>

  getPaginatedListByIssueID(
    id: string,
    q: Query<IssueHistoryQuery>
  ): Observable<IssueHistory[] | undefined>

  add(e: IssueHistoryDTO): Observable<IssueHistory | undefined>

  update(e: IssueHistoryDTO): Observable<IssueHistory | undefined>

  getCountByIssueID(id: string): Observable<number>
}

export class IssueHistoryService implements IIssueHistoryService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IIssueHistoryApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IIssueHistoryApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: IssueHistoryDTO): Observable<IssueHistory | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<IssueHistory | undefined> {
    return this._api.getByID(id)
  }

  update(e: IssueHistoryDTO): Observable<IssueHistory | undefined> {
    return this._api.update(e)
  }

  getByIssueID(id: string): Observable<IssueHistory[] | undefined> {
    return this._api.getByIssueID(id)
  }

  getPaginatedListByIssueID(
    id: string,
    q: Query<IssueHistoryQuery>
  ): Observable<IssueHistory[] | undefined> {
    return this._api.getPaginatedListByIssueID(id, q)
  }

  getCountByIssueID(id: string): Observable<number> {
    return this._api.getCountByIssueID(id)
  }
}
