import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Box, Grid } from '@mui/material'
import { emptyIssueDTO, IssueDTO } from '../../modules/issues/models/Issue'
import DividerTheme from '../../components/divider/DividerTheme'
import CustomSelect from '../../components/form/CustomSelect'
import CustomTextField from '../../components/form/CustomTextField'
import iconAddLocation from '../../assets/add-location.svg'
import CustomButton from '../../components/form/CustomButton'
import CustomInputFile from '../../components/form/CustomInputFile'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_ISSUES } from '../../routes/routes-constants'
import { getIssueContainer } from '../../container/issue-module'
import { IIssueService } from '../../modules/issues'
import {
  ADDRESS_SERVICE_KEY,
  ISSUE_SERVICE_KEY,
  ISSUEHISTORY_SERVICE_KEY,
  ISSUETYPE_SERVICE_KEY,
  STATE_SERVICE_KEY,
} from '../../modules/issues/container'
import {
  Address,
  AddressDTO,
  emptyAddressDTO,
  parseAddress,
} from '../../modules/issues/models/Address'
import { IStateService } from '../../modules/issues/services/StateService'
import { IIssueTypeService } from '../../modules/issues/services/IssueTypeService'
import { State } from '../../modules/issues/models/State'
import { IssueType } from '../../modules/issues/models/IssueType'
import { User } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { UserService } from '../../modules/users/services/UserService'
import { IAddressService } from '../../modules/issues/services/AddressService'
import { v4 as uuidv4 } from 'uuid'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { AddressPickerDialog } from '../map/AddressPickerDialog'
import AddressAutocomplete from '../../components/address-autocomplete/AddressAutocomplete'
import { getDistrict } from '../../common/utils/MapUtils'
import { FileDTO, fromModel } from '../../modules/files/models/File'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files/container'
import { Query, QueryParam } from 'common/api/Query'
import removeIcon from '../../assets/remove.svg'
import { emptyIssueHistoryDTO, IssueHistoryDTO } from '../../modules/issues/models/IssueHistory'
import { IIssueHistoryService } from '../../modules/issues/services/IssueHistoryService'
import { makeStyles } from '@material-ui/core'
import { ImageType } from '../../modules/files/enums/ImageType'

export type IssueEditProps = {
  id?: number
  address?: AddressDTO
  issue?: IssueDTO
} & RouteProps

interface EditableFile extends FileDTO {
  created?: boolean
  deleted?: boolean
}

type ImageHeap = {
  [ImageType.ISSUE_IMAGE]: EditableFile[]
  [ImageType.EXECUTION_IMAGE]: EditableFile[]
  [ImageType.RESOLUTION_IMAGE]: EditableFile[]
}

const useStyles = makeStyles({
  imageContainerLabel: {
    marginTop: 0,
    marginBottom: 15,
    fontWeight: 'bold',
    color: 'black',
  },
  issueImageContainer: {
    width: '200px',
    height: '200px',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'relative',
  },
  removeIcon: {
    float: 'right',
    top: '5%',
    right: '5%',
    width: '35px',
    borderRadius: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

const imageSorter = (file1: EditableFile, file2: EditableFile) => {
  if (isFinite(file1.index - file2.index)) {
    return file1.index - file2.index
  }
  return isFinite(file1.index) ? -1 : 1
}

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const issueHistoryService = getIssueContainer().get<IIssueHistoryService>(ISSUEHISTORY_SERVICE_KEY)
const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)
const addressService = getIssueContainer().get<IAddressService>(ADDRESS_SERVICE_KEY)
const stateService = getIssueContainer().get<IStateService>(STATE_SERVICE_KEY)
const issueTypeService = getIssueContainer().get<IIssueTypeService>(ISSUETYPE_SERVICE_KEY)
const usersService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const loggedUser = loggedUserService.get()

export function IssueEdit(props: IssueEditProps) {
  const title = props.title || ''
  const navigate = useNavigate()
  const location = useLocation()
  const [issue, setIssue] = React.useState<IssueDTO>(emptyIssueDTO())
  const [address, setAddress] = React.useState<AddressDTO>()
  const [prevState, setPrevState] = React.useState<State>()
  const [states, setStates] = React.useState<State[]>([])
  const [issueTypes, setIssueTypes] = React.useState<IssueType[]>([])
  const [responsibles, setResponsibles] = React.useState<User[]>([])
  const [openAddressDialog, setOpenAddressDialog] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const classes = useStyles()

  const [images, setImages] = useState<ImageHeap>({
    [ImageType.ISSUE_IMAGE]: [],
    [ImageType.EXECUTION_IMAGE]: [],
    [ImageType.RESOLUTION_IMAGE]: [],
  })
  const [errors, setErrors] = React.useState<Map<keyof IssueDTO | keyof AddressDTO, string>>(
    new Map()
  )

  useEffect(() => {
    if (location?.state?.id) {
      fileService
        .getFilteredList(
          new Query({
            query: [new QueryParam('issueID', location.state.id || '')],
          })
        )
        .subscribe((res) => {
          const importedImages: ImageHeap = {
            [ImageType.ISSUE_IMAGE]: [],
            [ImageType.EXECUTION_IMAGE]: [],
            [ImageType.RESOLUTION_IMAGE]: [],
          }

          res.items.forEach((item) => {
            let img: EditableFile = { ...fromModel(item), created: false, deleted: false }
            importedImages[img.type].push(img)
          })

          setImages(importedImages)
        })
    }
  }, [])

  //TODO: Remove this. Filter on backend based on workflow configuration
  function filterStates(state: State): boolean {
    const stateName = state.name.toLowerCase()
    if (prevState) {
      const currentState = prevState.name.toLowerCase()
      if (currentState === stateName) {
        return true
      } else if (currentState === 'incidencia solicitada') {
        return stateName === 'en gestión' || stateName === 'no pertenece al servicio'
      } else if (currentState === 'en gestión') {
        return stateName === 'en ejecución'
      } else if (currentState === 'en ejecución') {
        return stateName === 'gestionada / solucionada'
      }
    }
    return stateName === 'incidencia solicitada'
  }

  React.useEffect(() => {
    if (props.address) {
      setAddress(props.address)
    }
  }, [props.address])

  React.useEffect(() => {
    stateService.getAll().subscribe((st) => st && setStates(st.filter(filterStates)))
  }, [prevState])

  React.useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        issueService.getByID(location.state.id).subscribe((i) => {
          if (i) {
            setIssue(i.toDTO())
            setAddress(i.address.toDTO())
            setPrevState(new State(i.state.toDTO()))
          }
        })
      }
      location.state.issue && setIssue(location.state.issue)
      location.state.address && setAddress(location.state.address)
    } else {
      setIssue(emptyIssueDTO())
      setAddress(emptyAddressDTO())
    }

    issueTypeService.getAll().subscribe((types) => types && setIssueTypes(types))
    usersService.getAll().subscribe((users) => users && setResponsibles(users))
  }, [])

  React.useEffect(() => {
    if (errors.size > 0) {
      isFormValid()
    }
  }, [issue, address])

  function goBack() {
    navigate(ROUTE_ISSUES)
  }

  const handleChangeImage = (type: ImageType, fileBase64: string) => {
    let start = fileBase64.indexOf('/') + 1
    let end = fileBase64.indexOf(';')
    let extension = fileBase64.substring(start, end)

    const img: EditableFile = {
      id: uuidv4(),
      issueID: issue.id,
      base64: fileBase64.substring(fileBase64.indexOf(',') + 1, fileBase64.length),
      type: type,
      extension: extension,
      index: NaN,
      created: true,
      deleted: false,
    }
    setImages((prev) => {
      return { ...prev, [type]: [...prev[type], { ...img, index: prev[type].length }] }
    })
  }

  function handleFormChange(
    field: keyof IssueDTO | keyof AddressDTO,
    value: any,
    isAddress: boolean = false
  ) {
    if (isAddress && address) {
      setAddress({ ...address, [field]: value })
    } else if (issue) {
      setIssue({ ...issue, [field]: value })
    }
  }

  function getFormErrors(): Map<keyof IssueDTO | keyof AddressDTO, string> {
    const err: Map<keyof IssueDTO | keyof AddressDTO, string> = new Map()
    if (!issue?.typeID) err.set('typeID', 'Indique el tipo de incidencia')
    if (!issue?.responsibleID) err.set('responsibleID', 'Indique el responsable asignado')
    if (!issue?.stateID) err.set('stateID', 'Indique el estado')
    if (!issue?.description) err.set('description', 'Indique una breve descripción')

    if (!address?.address) err.set('address', 'Dirección no válida')
    if (!address?.zipcode) err.set('zipcode', 'CP no válido')

    return err
  }

  function isFormValid(): boolean {
    const err = getFormErrors()
    const valid: boolean = err.size === 0
    setErrors(err)
    return valid
  }

  function saveAddress(cb?: (addr: Address) => void) {
    if (address) {
      if (issue.addressID) {
        addressService
          .update({ ...address, id: issue.addressID })
          .subscribe((a) => a && cb && cb(a))
      } else {
        addressService.add({ ...address, id: uuidv4() }).subscribe((a) => a && cb && cb(a))
      }
    }
  }

  const removeImage = (type: ImageType, id: string) => {
    setImages((prev) => {
      return {
        ...prev,
        [type]: prev[type].map((image) => (image.id === id ? { ...image, deleted: true } : image)),
      }
    })
  }

  const imagesHeapToView = (type: ImageType, label: string) => {
    return (
      <>
        <h4 className={classes.imageContainerLabel}>{label}</h4>
        <Grid container spacing={2}>
          {images[type]
            .filter((image) => !image.deleted)
            .sort(imageSorter)
            .map((image, index) => {
              return (
                <Grid item key={index} sx={{ marginBottom: 2 }}>
                  <div
                    className={classes.issueImageContainer}
                    style={{
                      background: `no-repeat center url(data:image/${image.extension};base64,${image.base64})`,
                      backgroundSize: 'cover',
                    }}
                  >
                    <img
                      src={removeIcon}
                      className={classes.removeIcon}
                      onClick={() => removeImage(image.type, image.id)}
                    />
                  </div>
                </Grid>
              )
            })}
        </Grid>
        <CustomInputFile
          onFileRead={(file) => file && handleChangeImage(type, file.base64)}
          fullWidth={true}
        />
      </>
    )
  }

  function saveIssue(iss: IssueDTO, cb?: (iss: IssueDTO) => void) {
    if (iss.id) {
      issueService.update(iss).subscribe(async (i) => {
        for (const heap of Object.values(images)) {
          const newImagesHeap = heap.filter((i) => !i.deleted).sort(imageSorter)
          for (const image of heap) {
            const aux: EditableFile = { ...image }
            delete aux.created
            delete aux.deleted

            if (image.created && !image.deleted) {
              await fileService.add({ ...aux, index: newImagesHeap.indexOf(image) }).toPromise()
            } else if (image.deleted && !image.created) {
              await fileService.delete(image.id).toPromise()
            } else if (!image.created && !image.deleted) {
              const newIndex = newImagesHeap.indexOf(image)
              if (newIndex !== image.index) {
                await fileService.update({ ...aux, index: newIndex }).toPromise()
              }
            }
          }
        }

        if (prevState && prevState.id !== iss.stateID) {
          const history: IssueHistoryDTO = {
            ...emptyIssueHistoryDTO(),
            issueID: iss.id,
            id: uuidv4(),
            userID: loggedUser?.id || '',
            createdAt: new Date(),
            remarks: '',
          }
          stateService.getByID(iss.stateID).subscribe((state) => {
            issueHistoryService.add({ ...history, remarks: state.name }).subscribe(() => {
              setLoading(false)
              navigate(ROUTE_ISSUES)
            })
          })
        } else {
          setLoading(false)
          navigate(ROUTE_ISSUES)
        }
      })
    } else {
      issueService
        .add({ ...iss, userID: loggedUser?.id || '', id: uuidv4() })
        .subscribe(async (i) => {
          for (const heap of Object.values(images)) {
            const newImagesHeap = heap.filter((i) => !i.deleted).sort(imageSorter)
            for (const [index, image] of newImagesHeap.entries()) {
              const aux: EditableFile = { ...image }
              delete aux.created
              delete aux.deleted

              if (image.created && !image.deleted) {
                await fileService.add({ ...aux, issueID: i?.id || '', index: index }).toPromise()
              }
            }
          }

          setLoading(false)
          navigate(ROUTE_ISSUES)
        })
    }
  }

  function save() {
    if (address && issue && isFormValid()) {
      setLoading(true)

      saveAddress((storedAddress) => {
        const newIssue: IssueDTO = {
          ...issue,
          addressID: storedAddress?.id || '',
          lng: address.lng || issue.lng,
          lat: address.lat || issue.lat,
        }
        saveIssue(newIssue)
      })
    }
  }

  const handleAddressChange = (place: google.maps.places.PlaceResult) => {
    if (!place || !place.geometry || !place.geometry.location) {
      return
    }
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const addr = parseAddress(address?.district || '', place.address_components || [], lat, lng)

    // Remove house number if it does not exist
    addr.address = addr.address.replace(', undefined', '')

    getDistrict(lat, lng)
      .then((district) => {
        setAddress({
          ...addr,
          district: district,
          id: address?.id || '',
          zipcode: addr.zipcode || '-',
        })
      })
      .catch((_) => {
        setAddress({ ...addr, district: '-', id: address?.id || '', zipcode: addr.zipcode || '-' })
      })
  }

  return (
    <Box>
      {openAddressDialog && (
        <AddressPickerDialog
          onClose={() => setOpenAddressDialog(false)}
          open={true}
          lat={address?.lat}
          title={'Detalle Dirección'}
          lng={address?.lng}
          district={address?.district}
          successLabel={'OK'}
          onSuccess={(addr, lat, lng) => setOpenAddressDialog(false)}
        />
      )}
      <DividerTheme title={title.toUpperCase()} />
      <Box style={{ marginTop: 20 }}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <CustomSelect
              label={'Tipo de Incidencia'}
              id={'type'}
              emptyOption
              value={issue?.typeID}
              errorLabel={errors.get('typeID')}
              onChangeOption={(value) => handleFormChange('typeID', value)}
              options={issueTypes.map((it) => {
                return { value: it.id, label: it.name }
              })}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelect
              label={'Responsable'}
              id={'type'}
              emptyOption
              errorLabel={errors.get('responsibleID')}
              onChangeOption={(value) => handleFormChange('responsibleID', value)}
              value={issue?.responsibleID}
              options={responsibles?.map((u) => {
                return { value: u.id, label: u.firstName + ' ' + u.lastName }
              })}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelect
              label={'Estado'}
              id={'type'}
              value={issue?.stateID}
              errorLabel={errors.get('stateID')}
              onChangeOption={(value) => handleFormChange('stateID', value)}
              defaultOneOption
              options={states?.map((it) => {
                return { value: it.id, label: it.name }
              })}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomTextField
              fullWidth={true}
              readOnly
              value={address?.zipcode}
              label={'Código Postal'}
              labelColor="black"
              errorLabel={errors.get('zipcode')}
            />
          </Grid>
          <Grid item xs={8}>
            <AddressAutocomplete
              fullWidth={true}
              onPlaceSelect={handleAddressChange}
              value={address?.address}
              label={'Dirección'}
              onChange={(e) => handleFormChange('address', e.target.value, true)}
              errorLabel={errors.get('address')}
              icon={{ icon: iconAddLocation, onClick: () => setOpenAddressDialog(true) }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextField
              fullWidth={true}
              rows={8}
              onChange={(e) => handleFormChange('description', e.target.value)}
              value={issue?.description}
              errorLabel={errors.get('description')}
              multiline
              label={'Descripción de la incidencia (máx. 140 caracteres)'}
              labelColor="black"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} direction={'row'} style={{ marginTop: 4 }}>
          <Grid item>{imagesHeapToView(ImageType.ISSUE_IMAGE, 'Imágenes de la incidencia')}</Grid>
          <Grid item>
            {imagesHeapToView(ImageType.EXECUTION_IMAGE, 'Imágenes de la ejecución')}
          </Grid>
          <Grid item>
            {imagesHeapToView(ImageType.RESOLUTION_IMAGE, 'Imágenes de la resolución')}
          </Grid>
        </Grid>
        <Box style={{ marginTop: 60 }} display={'flex'} justifyContent={'space-between'}>
          <CustomButton loading={loading} onClick={save} color={'primary'}>
            {'GUARDAR'}
          </CustomButton>
          <CustomButton onClick={goBack}>{'CANCELAR'}</CustomButton>
        </Box>
      </Box>
    </Box>
  )
}
