import { Box, BoxProps, Divider } from '@mui/material'
import React from 'react'

export interface DividerAction {
  icon?: string
  iconWidth?: string | number
  iconHeight?: string | number
  onClick?: () => void
}

interface DividerThemeProps extends BoxProps {
  title?: string
  action?: DividerAction
  fontColor?: string
  fontSize?: any
}

function DividerTheme({
  title = '',
  fontSize = '2em',
  fontColor = 'var(--color-secundary-theme)',
  action,
  children,
  ...rest
}: DividerThemeProps) {
  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <span style={{ color: fontColor, fontSize: fontSize, fontWeight: 'bold' }}>{title}</span>
        <img
          style={{ cursor: 'pointer', width: action?.iconWidth, height: action?.iconHeight }}
          src={action?.icon}
          onClick={action?.onClick}
        />
      </Box>
      <Divider style={{ marginTop: 5, height: 2, backgroundColor: 'var(--color-orange-theme)' }} />
    </Box>
  )
}

export default DividerTheme
